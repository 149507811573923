import styled from 'styled-components'

export const FaqWrapper = styled.section`
  padding: 5.25rem 5.125rem;
  display: flex;
  @media only screen and (max-width: 900px) {
    display: block;
    padding: 3rem 1.2rem 8rem;
  }
`

export const Heading = styled.div`
  flex: 2;
  padding-top: 8px;
  > h2 {
    margin-right: 5.375rem;
  }
  @media only screen and (max-width: 900px) {
    padding-bottom: 3rem;
    > h2 {
      margin-right: 0;
    }
  }
`

export const Questions = styled.ul`
  flex: 9;

  > li {
    h4 {
      font-weight: 500;
      line-height: 2rem;
      padding-bottom: 1rem;
      font-size: 21px;
    }

    margin-bottom: 36px;
    @media only screen and (max-width: 900px) {
      margin-bottom: 48px;
    }
  }

  .nested-list {
    list-style-type: lower-roman;
    > li {
      font-size: 17px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.grey1};
      margin-bottom: 0.5rem;
      margin-left: 22px;
      whitespace: preline;
    }
  }
`

export const Paragraphs = styled.div`
  p {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey1};
    white-space: pre-line;
    margin-bottom: 1rem;
    font-size: 17px;
  }
`

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 54px;
  line-height: 1.4;
  font-weight: 600;
  @media only screen and (max-width: 1200px) {
    font-size: 44px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 34px;
  }
`
