import React from 'react'
import { graphql } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'

// Shared UI
import { Showcase, CtaButton, HeaderBlock, ListItem } from 'shared-ui'

// Features
import Layout from '../../features/Layout'
import SEO from '../../features/Seo'

// Primitives

import Signatories from '../../primitives/Signatories'
import Principals from '../../features/Principals'

export const query = graphql`query CompanyPledgePageQuery {
  showcase1: file(relativePath: {eq: "showcase1.png"}) {
    childImageSharp {
      gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
    }
  }
  showcase2: file(relativePath: {eq: "showcase2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
    }
  }
  showcase3: file(relativePath: {eq: "showcase3.png"}) {
    childImageSharp {
      gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
    }
  }
}
`

const CompanyPledgePage = ({ data }) => {
  const companyPledgePageData = {
    noSupporters: true,
    footer: {
      head: `Contact us today!`,
      sub: `Fill out our form and we will be in touch shortly. We look forward to speaking with you.`,
      buttons: (
        <CtaButton inverse bgColor="white" slug="/contact/">
          Contact Us
        </CtaButton>
      ),
    },
    showcase1: {
      cards: [
        {
          image: data.showcase1.childImageSharp.gatsbyImageData,
          name: `Kelsey Cavanaugh`,
          role: `Healthcare Manager at Protiviti`,
          headPhrase: 'Looking to help with:',
          options: [
            `Career Planning`,
            `Effective Networking`,
            `Interview Strategies`,
          ],
        },
        {
          image: data.showcase2.childImageSharp.gatsbyImageData,
          name: `Tracy Preston`,
          role: `Executive VP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [`Building a Personal Brand`, `Career Advice`, `Networking`],
        },
        {
          image: data.showcase3.childImageSharp.gatsbyImageData,
          name: `Stefanie Tsen`,
          role: `SVP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [
            `Understanding Marketing`,
            `Inspiring Others`,
            `Networking for your Future`,
          ],
        },
      ],
    },
    listItems: [
      {
        color: `#fe4f67`,
        word: `Remove Barriers`,
        paragraph: `by adopting new thinking around how to engage and build relationships with underrepresented groups and how to help students join the organization.`,
      },
      {
        color: `#d14264`,
        word: `Define Success`,
        paragraph: `and the desired impact for our organization.`,
      },
      {
        color: `#a33561`,
        word: `Engage The Community`,
        paragraph: `by sharing our commitment to the next generation through posts and videos on social media and encouraging our employees/corporate leaders to register.`,
      },
      {
        color: `#69255e`,
        word: `Meet Students Where They Are`,
        paragraph: `and take a mobile first approach to offer help where it is needed most through the app which eliminates the limitations of local proximity and physical interactions.`,
      },
      {
        color: `#38175b`,
        word: `Close The Gap`,
        paragraph: `by helping underrepresented students reach their full potential.`,
      },
    ],
  }
  return (
    <Layout pageData={companyPledgePageData}>
      <SEO title="Company Pledge" />
      <HeaderBlock
        position="flex-end"
        headerText="The 100k Mentor Challenge Corporate Pledge"
        pad="7rem 2rem 0"
      />
      <HeaderBlock
        pad="0"
        subMax={1050}
        subText={
          <>
            <p
              style={{
                textAlign: `center`,
                margin: `0 40px 2rem`,
              }}
            >
              We join with the 100k Mentor Challenge and commit our organization
              to support and mentor college students during this challenging
              time.
            </p>
            <p
              style={{
                textAlign: `left`,
                fontWeight: `500`,
                color: `black`,
                fontSize: `2rem`,
                padding: `20px 0 0 0`,
                marginBottom: `.6rem`,
                maxWidth: '1000px',
                width: '100%',
              }}
            >
              To reach our goal, we will:
            </p>
          </>
        }
      >
        <ol>
          {companyPledgePageData.listItems.map((item) => (
            <ListItem
              key={uuidv4()}
              liColor={item.color}
              paragraph={item.paragraph}
              word={item.word}
            />
          ))}
        </ol>
      </HeaderBlock>
      <div style={{ width: `100%`, display: `flex`, justifyContent: `center` }}>
        <CtaButton slug="/contact/">Take the pledge</CtaButton>
      </div>
      <Principals />
      <HeaderBlock headerText="Our Signatories" pad="8rem 24px 2rem" />
      <Signatories />
      <HeaderBlock
        headerText="With just one hour a month, a mentor can change a student’s life."
        pad="4rem 0 3rem 0"
        headMax={1080}
      />
      <Showcase
        content={companyPledgePageData.showcase1.cards}
        headPhrase="Looking to help with:"
      />
    </Layout>
  )
}

export default CompanyPledgePage
