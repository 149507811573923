import React from 'react'
import { graphql } from 'gatsby'

// Shared UI
import {
  HeaderBlock,
  SlideTrifold,
  SplitScreen,
  OverlapBlock,
  OverlapHero,
  CtaButton,
  Showcase,
} from 'shared-ui'

// Features
import Layout from '../../features/Layout'
import SEO from '../../features/Seo'

// Svg icons
import { Page, Bullseye, Cycle } from '../../primitives/Icons'

import Right from '../../assets/students-bottom-right.svg'
import Left from '../../assets/students-left.svg'

export const query = graphql`query StudentsPageQuery {
  hero: file(relativePath: {eq: "studentsHero.png"}) {
    childImageSharp {
      gatsbyImageData(width: 402, quality: 100, layout: CONSTRAINED)
    }
  }
  g1: file(relativePath: {eq: "studentsG1.png"}) {
    childImageSharp {
      gatsbyImageData(width: 505, quality: 100, layout: CONSTRAINED)
    }
  }
  g2: file(relativePath: {eq: "studentsG2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 517, quality: 100, layout: CONSTRAINED)
    }
  }
  g3: file(relativePath: {eq: "studentsG3.png"}) {
    childImageSharp {
      gatsbyImageData(width: 613, quality: 100, layout: CONSTRAINED)
    }
  }
  g4: file(relativePath: {eq: "studentsG4.png"}) {
    childImageSharp {
      gatsbyImageData(width: 575, quality: 100, layout: CONSTRAINED)
    }
  }
  studentsHero2: file(relativePath: {eq: "overlapHero2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  overlapProfile: file(relativePath: {eq: "saraiProfile.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 81, quality: 100, layout: FIXED)
    }
  }
  showcase1: file(relativePath: {eq: "showcase1.png"}) {
    childImageSharp {
      gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
    }
  }
  showcase2: file(relativePath: {eq: "showcase2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
    }
  }
  showcase3: file(relativePath: {eq: "showcase3.png"}) {
    childImageSharp {
      gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
    }
  }
}
`

const StudentsPage = ({ data }) => {
  const studentsPageData = {
    hero: {
      pad: '3.6rem 3rem 0 2rem',
      max: '402px',
      bgColor: '#f9faff',
      innerMax: '1240px',
      headMax: '490px',
      mobileBar: {
        text: 'Ready to Meet Your Mentor?  |  Click Here',
        to: '/students/downloads/',
      },
      heroText: 'Get the guidance you need in this turbulent time',
      subText: (
        <p>
          Companies are rescinding internships and full time offers. A mentor
          can help you build a network and guide you to your first job.
        </p>
      ),
      graphic: data.hero.childImageSharp.gatsbyImageData,
      vectors: [
        {
          vector: <Right />,
          styles: {
            bottom: '-2px',
            right: '0',
            position: 'absolute',
          },
        },
        {
          vector: <Left />,
          styles: { bottom: '0', left: '0', position: 'absolute' },
        },
      ],
      slug: '/students/downloads/',
    },
    trifold1: {
      cards: [
        {
          icon: <Page />,
          head: `Register`,
          sub: `We learn about what you want to achieve through the program`,
        },
        {
          icon: <Bullseye />,
          head: `ID Match`,
          sub: `We match you with up to 5 potential mentors`,
        },
        {
          icon: <Cycle />,
          head: `Start the Mentorship`,
          sub: `You and your mentor are connected for a four month guided program`,
        },
      ],
    },
    showcase1: {
      cards: [
        {
          image: data.showcase1.childImageSharp.gatsbyImageData,
          name: `Kelsey Cavanaugh`,
          role: `Healthcare Manager at Protiviti`,
          headPhrase: 'Looking to help with:',
          options: [
            `Career Planning`,
            `Effective Networking`,
            `Interview Strategies`,
          ],
        },
        {
          image: data.showcase2.childImageSharp.gatsbyImageData,
          name: `Tracy Preston`,
          role: `Executive VP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [`Building Personal Brand`, `Career Advice`, `Networking`],
        },
        {
          image: data.showcase3.childImageSharp.gatsbyImageData,
          name: `Stefanie Tsen`,
          role: `SVP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [
            `Understanding Marketing`,
            `Inspiring Others`,
            `Networking for your Future`,
          ],
        },
      ],
    },
    footer: {
      head: `Ready to meet your mentor?`,
      sub: `Sign up today and get matched with your ideal mentor to help put you on the path towards your dream career.`,
      buttons: (
        <CtaButton slug={'/students/downloads/'} inverse bgColor="white">
          Get Started
        </CtaButton>
      ),
      subMax: 800,
    },
  }
  return (
    <Layout pageData={studentsPageData}>
      <SEO title="Students" />

      <OverlapBlock
        head="Sign up today for the 100k Mentor Challenge"
        sub="Get the practical guidance you need to stand out in today’s job market. Connect 1-on-1 with a professional in your field for four months. Entirely remote. Always free."
        buttons={
          <CtaButton slug={'/students/downloads/'}>Download the App</CtaButton>
        }
        graphic={data.g1.childImageSharp.gatsbyImageData}
        maxWidth={455}
        maxHeight={700}
        rightPad="100px"
        textMax="700px"
        imgMargin="-60px 0 -60px -70px"
      />

      <HeaderBlock
        pad="12rem 0 3rem"
        headerText="With just one hour a month, a mentor can change your life"
        headMax={950}
      />

      <OverlapHero
        hero={data.studentsHero2.childImageSharp.gatsbyImageData}
        profileImg={data.overlapProfile.childImageSharp.gatsbyImageData}
        quote="“Over the last 4 months, my mentor helped me identify my career path and really taught me how to network. It changed my perspective on how to get my next job.”"
        profileName="Sarai Flores, Penn State University"
        role="100k Mentor Program Participant"
      />

      <HeaderBlock headerText="How does it work?" pad="2rem 0 3rem 0" />

      <SlideTrifold cards={studentsPageData.trifold1.cards} />

      <div
        style={{
          width: `100%`,
          display: `flex`,
          justifyContent: `center`,
          paddingTop: `4.5rem`,
        }}
      >
        <CtaButton slug={'/students/downloads/'}>Apply Today</CtaButton>
      </div>

      <HeaderBlock
        position="flex-end"
        pad="10rem 24px 8rem"
        headerText="A 21st century mentorship program connecting you with professionals across the country"
      />

      <SplitScreen
        first
        head="Learn from professionals in your field of interest"
        sub="Our Mentors are drawn from a variety of industries and fields. Whether you have no idea where you want to go or you have identified your top 5 firms, our mentors will give you the advice you need to successfully transition from college to the professional world."
        graphic={data.g2.childImageSharp.gatsbyImageData}
        gWidth={517}
        gHeight={692}
        pad="0 23px 8rem"
        textMax={'535px'}
      />

      <SplitScreen
        reverse
        head="Build your networks and establish connections"
        sub="We help you build lifelong relationships with professionals in your field. Through the 100k Program you will not only get guidance and support, but every 4 months you will have the option to switch mentors so you can get a variety of perspectives and connections."
        graphic={data.g3.childImageSharp.gatsbyImageData}
        gWidth={503}
        gHeight={617}
        textMax="535px"
        innerMax="1050px"
      />

      <SplitScreen
        head="A schedule that fits your life"
        sub="We know life is hectic. Engage at your pace with a program that flexibly adjusts to and accounts for your schedule. We have also built a series of guides to ensure the time spent with your mentor is impactful and helpful to your career search."
        graphic={data.g4.childImageSharp.gatsbyImageData}
        gWidth={485}
        gHeight={685}
        pad="8rem 2rem 10rem"
      />

      <HeaderBlock align="center" headerText="Meet some of our mentors" />

      <Showcase
        content={studentsPageData.showcase1.cards}
        headPhrase="Looking to help with:"
      />
    </Layout>
  );
}

export default StudentsPage
