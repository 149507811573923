import React from 'react'
import { graphql } from 'gatsby'
import { withArtDirection, getImage } from 'gatsby-plugin-image'
import { HowItWorks, CtaButton } from 'shared-ui'
import Seo from '../../features/Seo'
import Layout from '../../features/Layout'

import { FirstGen, Pocs, LGBT, Vet, Ada } from '../../primitives/Icons'

export const query = graphql`
  query CompanyHIWPageQuery {
    split1Main: file(relativePath: { eq: "split1CompaniesMain.png" }) {
      childImageSharp {
        gatsbyImageData(width: 448, quality: 100, layout: CONSTRAINED)
      }
    }
    split1Head: file(relativePath: { eq: "split1CompaniesHead.png" }) {
      childImageSharp {
        gatsbyImageData(width: 88, quality: 100, layout: CONSTRAINED)
      }
    }
    split2p: file(relativePath: { eq: "split1CompaniesHead.png" }) {
      childImageSharp {
        gatsbyImageData(width: 88, quality: 100, layout: FIXED)
      }
    }
    imageSectionLeft: file(
      relativePath: { eq: "imageSectionLeftMentors.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    imageSectionCenter: file(
      relativePath: { eq: "imageSectionCenterMentors.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 428, quality: 100, layout: CONSTRAINED)
      }
    }
    imageSectionRight: file(
      relativePath: { eq: "imageSectionRightMentors.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 332, quality: 100, layout: CONSTRAINED)
      }
    }
    lambs: file(relativePath: { eq: "statRowsLambs.png" }) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    tati: file(relativePath: { eq: "statRowsTatianna.png" }) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    split3a: file(relativePath: { eq: "split3a.png" }) {
      childImageSharp {
        gatsbyImageData(width: 374, quality: 100, layout: CONSTRAINED)
      }
    }
    arrowGMain: file(relativePath: { eq: "companiesArrowGMain.png" }) {
      childImageSharp {
        gatsbyImageData(width: 371, quality: 100, layout: CONSTRAINED)
      }
    }
    arrowRow: file(relativePath: { eq: "companiesArrowG.png" }) {
      childImageSharp {
        gatsbyImageData(width: 370, quality: 100, layout: CONSTRAINED)
      }
    }
    split4Main: file(relativePath: { eq: "fakeSlider.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
      }
    }
    antonS: file(relativePath: { eq: "antonS.png" }) {
      childImageSharp {
        gatsbyImageData(width: 60, quality: 100, layout: FIXED)
      }
    }
    last: file(relativePath: { eq: "HIWFooterA.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    last_tablet: file(relativePath: { eq: "HIWFooterA_tablet.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    last_mobile: file(relativePath: { eq: "HIWFooterA_mobile.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`

const HowItWorksPage = ({ data }) => {
  const pageData = {
    noSupporters: true,
    howItWorks: {
      split1: {
        graphicHeadComponent: {
          img: data.split1Head.childImageSharp.gatsbyImageData,
          text:
            'How Baker Hughes partnered with ProMazo for the 100k Mentor Challenge',
        },
        graphic: data.split1Main.childImageSharp.gatsbyImageData,
        quoteText:
          "Mentoring future leaders helps develop future workforces, and also helps engage our company's associates",
        quotePad: '4rem 0',
        arrowLink: '/companies/',
        maxWidth: '610px',
        ctaLink: '/contact/',
      },
      separator: {
        text1: "Here's how we build a",
        bold: 'unique mentorship program',
        text2: 'for your organization',
      },
      imageSection: {
        headingWidth: '555px',
        heading: 'We connect mentors to students from diverse backgrounds',
        left: {
          fluid: data.imageSectionLeft.childImageSharp.gatsbyImageData,
          caption: 'John Tarell, USC',
        },
        center: {
          fluid: data.imageSectionCenter.childImageSharp.gatsbyImageData,
          caption: 'Arneil Farsh, Howard University',
        },
        right: {
          fluid: data.imageSectionRight.childImageSharp.gatsbyImageData,
          caption: 'Alicia Ramirez, UC Berkeley',
        },
      },
      split2: {
        headerText: 'It all begins with a roll out plan',
        subText:
          'We sit down with leaders ranging from HR to Talent Acquisition and D&I to understand the best way to partner with your company.',
        accordions: [
          {
            title: 'Where do the students come from?',
            content:
              'The students come from different underrepresented communities (women, people of color, LGBTQ+, veterans, first generation college students, community college students, and those with disabilities from the across the country.',
          },
          {
            title: 'What is a student portfolio?',
            content:
              'Your custom portfolio is selected based on 25 different variables. Your portfolio represents the students who we feel you can help the most.',
          },
          {
            title: 'How much does it cost?',
            content:
              'The program is completely free for mentors and mentees. However we do ask that you consider a sponsorship so that we can continue to expand our program and sustain it for the long term.',
          },
        ],
        carouselContent: {
          content: [
            {
              title: 'I. Initial Roll Out',
              profile: {
                fixed: data.split2p.childImageSharp.gatsbyImageData,
              },
              targetsSection: {
                heading: 'Company Goals',
                paras: [
                  <p>
                    Mentor Goals: <br />
                    <span>10 - 20 for the first season</span>
                  </p>,
                ],
              },
              recruitingOpps: {
                paras: [
                  <p>
                    Open Recruiting Fields: <br />
                    <span>Finance, IT, Accounting</span>
                  </p>,
                ],
              },
              desiredSkills: {
                header: 'Student Engagement Focus',
                content: [
                  {
                    skill: '1st Generation',
                    icon: <FirstGen />,
                  },
                  {
                    skill: 'POCs',
                    icon: <Pocs />,
                  },
                ],
              },
            },
            {
              title: 'II. Partnering with ERGs',
              profile: {
                fixed: data.split2p.childImageSharp.gatsbyImageData,
              },
              targetsSection: {
                heading: 'Company Goals',
                paras: [
                  <p>
                    Mentor Goals: <br />
                    <span>50 - 100 mentors</span>
                  </p>,
                ],
              },
              recruitingOpps: {
                paras: [
                  <p>
                    Open Recruiting Fields: <br />
                    <span>Finance, IT, Accounting, Marketing</span>
                  </p>,
                ],
              },
              desiredSkills: {
                header: 'Student Engagement Focus',
                content: [
                  {
                    skill: '1st Generation',
                    icon: <FirstGen />,
                  },
                  {
                    skill: 'LGBTQ+',
                    icon: <LGBT />,
                  },
                  {
                    skill: 'POCs',
                    icon: <Pocs />,
                  },
                ],
              },
            },
            {
              title: 'III. Full Roll Out',
              profile: {
                fixed: data.split2p.childImageSharp.gatsbyImageData,
              },
              targetsSection: {
                heading: 'Company Goals',
                paras: [
                  <p>
                    Mentor Goals: <br />
                    <span>200+ mentors</span>
                  </p>,
                ],
              },
              recruitingOpps: {
                paras: [
                  <p>
                    Open Recruiting Fields: <br />
                    <span>Finance, IT, Accounting, Marketing, HR</span>
                  </p>,
                ],
              },
              desiredSkills: {
                header: 'Student Engagement Focus',
                singleRow: true,
                content: [
                  {
                    skill: '1st Gen',
                    icon: <FirstGen />,
                  },
                  {
                    skill: 'LGBTQ+',
                    icon: <LGBT />,
                  },
                  {
                    skill: 'POCs',
                    icon: <Pocs />,
                  },
                  {
                    skill: 'Veteran',
                    icon: <Vet />,
                  },
                  {
                    skill: 'ADA',
                    icon: <Ada />,
                  },
                ],
              },
            },
          ],
        },
      },
      split3: {
        accordions: [
          {
            title: 'What is the profile of an ideal mentor?',
            content:
              'We want to bring mentors of all experience levels and backgrounds. We will work with you to determine the most appropriate people to bring into the program.',
          },
          {
            title: 'What is the length of a mentorship?',
            content:
              'The match is for four months. We call the period where a mentor is connected to a mentee a Season.',
          },
          {
            title: 'What are the commitments?',
            content:
              "We only ask that you help us spread the word through your company. We'll take care of everything else! Also we understand professionals are busy, we we only require that they meet with their mentee once a month.",
          },
        ],
        graphic: {
          fluid: data.split3a.childImageSharp.gatsbyImageData,
          caption: 'Justin Hoper, Northwestern University',
        },
      },
      statRowsImages: [
        {
          fluid: data.lambs.childImageSharp.gatsbyImageData,
          caption: 'Lambs Tao, NYU',
        },
        {
          fluid: data.tati.childImageSharp.gatsbyImageData,
          caption: 'Tatianna Keller, MIT',
        },
      ],
      statRows: [
        {
          stat: '100k',
          para: {
            head: 'Students',
            text:
              'Our national student pool comes from a diverse array of backgrounds, majors, and professional aspirations.',
          },
        },
        {
          stat: '300',
          para: {
            head: 'Club Partnerships',
            text:
              'We built a national coalition of student clubs and organizations to build our mentee network.',
          },
        },
        {
          stat: '1',
          para: {
            head: 'Mentee',
            text:
              'Employees are connected to a student that matches their background, profession, and interests. A mentor can have up to five mentees a Season.',
          },
        },
      ],
      arrowGraphic: {
        head:
          'Guide your mentees over a four month Season, meeting once per month',
        leftP: {
          head: 'Give feeback and advice',
          para:
            'Teach the next generation how to successfully take their first steps and jump start their professional career.',
        },
        rightP: {
          head: 'Students provide feedback',
          para:
            'Mentees give unprecidented insight into how they view the opportunities at your company as well as the communication and leadership skills of their mentors.',
          rowGraphic: data.arrowRow.childImageSharp.gatsbyImageData,
        },
        mainG: data.arrowGMain.childImageSharp.gatsbyImageData,
      },
      split4: {
        headerText: "Reimagine how you recruit in today's virtual age",
        headMax: '630px',
        innerMax: '1130px',
        subHead: 'Have an Immediate Impact',
        quoteText:
          'I had never considered working in the energy industry with my focus on sustainability, but after talking with my mentor I realized how many sustainable energy opportunities existed within Baker Hughes.',
        profile: {
          img: data.antonS.childImageSharp.gatsbyImageData,
          name: 'Anton S.',
        },
        graphic: data.split4Main.childImageSharp.gatsbyImageData,
        accordions: [
          {
            title: 'What happens at the end of a Season?',
            content:
              'At the end of a Season, mentors and mentees have the option of continuing with each other or requesting another match.',
          },
          {
            title: 'What if mentors are too busy next Season?',
            content:
              'We know work at companies is often seasonal so we allow for mentors to take a Season or two off if their life gets to busy.',
          },
          {
            title: 'Can we recruit students from the program?',
            content:
              'Yes. We see this as a new way for companies to engage and recruit students from underrepresented backgrounds.',
          },
        ],
      },
      last: {
        headerText:
          "Along the way your company will build 1,000's of connections with our diverse community...",
        maxWidth: '1100px',
        fluid: withArtDirection(getImage(data.last), [
          {
            media: `(max-width: 500px)`,
            image: getImage(data.last_mobile),
            height: data.last_mobile.childImageSharp.gatsbyImageData.height,
          },
          {
            media: `(max-width: 1200px)`,
            image: getImage(data.last_tablet),
            height: data.last_tablet.childImageSharp.gatsbyImageData.height,
          },
        ]),
      },
    },
    footer: {
      head: `Learn how your company can make an impact while recruiting the next generation of leaders`,
      headBgColor: '#441779',
      headMax: 1200,
      buttons: (
        <CtaButton slug="/contact/" bgColor="white" textColor="#441779">
          Contact Us
        </CtaButton>
      ),
    },
  }
  return (
    <Layout pageData={pageData}>
      <Seo title="How it Works - Companies" />
      <HowItWorks {...pageData.howItWorks} />
    </Layout>
  )
}

export default HowItWorksPage
