import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby'
import { SignatoriesWrapper, ImgWrapper } from './Signatories.styles'


const Signatories = () => {
  const logoData = useStaticQuery(graphql`query LogoQuery {
  l8: file(relativePath: {eq: "supporters9.png"}) {
    childImageSharp {
      gatsbyImageData(width: 260, quality: 100, layout: CONSTRAINED)
    }
  }
  l1: file(relativePath: {eq: "supporters1.png"}) {
    childImageSharp {
      gatsbyImageData(width: 353, quality: 100, layout: CONSTRAINED)
    }
  }
  l2: file(relativePath: {eq: "supporters3.png"}) {
    childImageSharp {
      gatsbyImageData(width: 405, quality: 100, layout: CONSTRAINED)
    }
  }
  l3: file(relativePath: {eq: "supporters2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
    }
  }
  l4: file(relativePath: {eq: "supporters4.png"}) {
    childImageSharp {
      gatsbyImageData(width: 340, quality: 100, layout: CONSTRAINED)
    }
  }
  l5: file(relativePath: {eq: "supporters5.png"}) {
    childImageSharp {
      gatsbyImageData(width: 319, quality: 100, layout: CONSTRAINED)
    }
  }
  l6: file(relativePath: {eq: "supporters6.png"}) {
    childImageSharp {
      gatsbyImageData(width: 200, quality: 100, layout: CONSTRAINED)
    }
  }
  l7: file(relativePath: {eq: "supporters7.png"}) {
    childImageSharp {
      gatsbyImageData(width: 319, quality: 100, layout: CONSTRAINED)
    }
  }
  l9: file(relativePath: {eq: "supporters8.png"}) {
    childImageSharp {
      gatsbyImageData(width: 220, quality: 100, layout: CONSTRAINED)
    }
  }
}
`)
  const photoList = Object.keys(logoData).map((key, i) => (
    <ImgWrapper className={key} key={key}>
      <GatsbyImage image={logoData[key].childImageSharp.gatsbyImageData} alt="Brand logo" />
    </ImgWrapper>
  ))

  return <SignatoriesWrapper>{photoList}</SignatoriesWrapper>
}

export default Signatories
