import React from 'react'
import Theme from './src/primitives/Theme/Theme'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import reset from 'styled-reset'

// Global Styles and reset for default styles
const GlobalStyle = createGlobalStyle`
    ${reset}

    ::placeholder {
      color: ${({ theme }) => theme.colors.label};
      opacity: 1;
      font-size: 18px;
      letter-spacing: 0.12px;
    }
  
    textarea,
    input {
      font-size: 18px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol' !important;
    }
    input:focus::placeholder,
    textarea:focus::placeholder {
      color: transparent;
    }

    p > strong {
      font-weight: 700;
    }
    
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.buttonDefault};
    }
    
    body, html {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"!important;
      > * {
        overflow-x: hidden;
      }
      font-size: 16px;
      @media only screen and (max-width: 1100px) {
        font-size: 14px;
      }
      @media ${(props) => props.theme.breakpoints.mobile} {
        font-size: 12px;
      }
    }

    h1 {
      font-size: ${(props) => props.theme.sizes.xxLarge};
    }

    h2 {
      font-size: 50px;
      line-height: 1.4;
      @media only screen and (max-width: 1200px) {
        font-size: 40px;
      }
      @media only screen and (max-width: 700px) {
        font-size: 30px;
      }
    }

    h3 {
      line-height: 1.3;
    }

    p {
      line-height: 1.3;
    }
    
    .disabled {
      pointer-events: none;
    }
  
  .tablet-text {
    @media only screen and (max-width: 1200px) {
      max-width: 800px !important;
    }
  }
  .no-mobile {
    @media only screen and (max-width: 500px){
      display: none;
    }
  }
`

export const onRouteUpdate = () => {
  if (typeof document !== `undefined`) {
    document.body.style.overflowY = 'scroll'
  }
}

// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
export const onClientEntry = () => {
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
    console.log(`👍 IntersectionObserver is polyfilled`)
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`)
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    require(`object-fit-images`)()
    console.log(`👍 Object-fit/Object-position are polyfilled`)
  }
}

// wrapRootElement is a gatsby built-in, used here to wrap the whole app
// with a theme to grab variables for styling through props.theme like in GlobalStyle

export const wrapPageElement = ({ element }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyle />
    {element}
  </ThemeProvider>
)
