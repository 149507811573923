import React from 'react'
import { graphql } from 'gatsby'
import { withArtDirection, getImage } from 'gatsby-plugin-image'
import { HowItWorks, CtaButton } from 'shared-ui'
import Seo from '../../features/Seo'
import Layout from '../../features/Layout'
import {
  Networking,
  Communication,
  BusinessStrategy,
  ShortBio,
} from '../../primitives/Icons'

export const query = graphql`
  query StudentsHIWPageQuery {
    split1Main: file(relativePath: { eq: "split1StudentsMain.png" }) {
      childImageSharp {
        gatsbyImageData(width: 448, quality: 100, layout: CONSTRAINED)
      }
    }
    split1Head: file(relativePath: { eq: "split1StudentsHead.png" }) {
      childImageSharp {
        gatsbyImageData(width: 88, quality: 100, layout: CONSTRAINED)
      }
    }
    split2p1: file(relativePath: { eq: "split2Studentsp1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 92, quality: 100, layout: FIXED)
      }
    }
    split2p2: file(relativePath: { eq: "split2Studentsp2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 92, quality: 100, layout: FIXED)
      }
    }
    split2p3: file(relativePath: { eq: "split2Studentsp3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 92, quality: 100, layout: FIXED)
      }
    }
    imageSectionLeft: file(relativePath: { eq: "imageSectionDeanna.png" }) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    imageSectionCenter: file(relativePath: { eq: "imageSectionEric.png" }) {
      childImageSharp {
        gatsbyImageData(width: 428, quality: 100, layout: CONSTRAINED)
      }
    }
    imageSectionRight: file(relativePath: { eq: "imageSectionStef.png" }) {
      childImageSharp {
        gatsbyImageData(width: 332, quality: 100, layout: CONSTRAINED)
      }
    }
    larry: file(relativePath: { eq: "statRowsLarry.png" }) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    kelsey: file(relativePath: { eq: "statRowsKelsey.png" }) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    split3a: file(relativePath: { eq: "captionImageTracy.png" }) {
      childImageSharp {
        gatsbyImageData(width: 374, quality: 100, layout: CONSTRAINED)
      }
    }
    arrowGMain: file(relativePath: { eq: "mentorArrowGMain.png" }) {
      childImageSharp {
        gatsbyImageData(width: 371, quality: 100, layout: CONSTRAINED)
      }
    }
    arrowRow: file(relativePath: { eq: "mentorArrowG.png" }) {
      childImageSharp {
        gatsbyImageData(width: 752, quality: 100, layout: CONSTRAINED)
      }
    }
    split4Main: file(relativePath: { eq: "fakeSlider2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
      }
    }
    antonS: file(relativePath: { eq: "antonS.png" }) {
      childImageSharp {
        gatsbyImageData(width: 60, quality: 100, layout: FIXED)
      }
    }
    last: file(relativePath: { eq: "HIWFooterB.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    last_tablet: file(relativePath: { eq: "HIWFooterB_tablet.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    last_mobile: file(relativePath: { eq: "HIWFooterB_mobile.png" }) {
      childImageSharp {
        gatsbyImageData(width: 356, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

const HowItWorksPage = ({ data }) => {
  const pageData = {
    noSupporters: true,
    howItWorks: {
      split1: {
        graphicHeadComponent: {
          img: data.split1Head.childImageSharp.gatsbyImageData,
          text:
            'How Alicia Ramirez found a mentor with the 100k Mentor Challenge',
        },
        graphic: data.split1Main.childImageSharp.gatsbyImageData,
        quoteText:
          'I want to understand how I can apply my degree in the professional world',
        quotePad: '4rem 0',
        maxWidth: '610px',
        arrowLink: '/students/',
        ctaLink: '/students/downloads/',
      },
      separator: {
        text1: "Here's how we",
        bold: 'find you',
        text2: 'the perfect mentor',
      },
      imageSection: {
        headingWidth: '520px',
        heading: 'You are connected to industry professionals',
        left: {
          fluid: data.imageSectionLeft.childImageSharp.gatsbyImageData,
          caption: 'Deanna Jones, Baker Hughes',
        },
        center: {
          fluid: data.imageSectionCenter.childImageSharp.gatsbyImageData,
          caption: 'Eric Severson, Neiman Marcus Group',
        },
        right: {
          fluid: data.imageSectionRight.childImageSharp.gatsbyImageData,
          caption: 'Stefanie Tsen, Neiman Marcus Group',
        },
      },
      split2: {
        headerText: 'It all begins with a portfolio of potential candidates',
        subText:
          'A quick, interactive sign up enables us to understand your preferences and bring you a group of 2-6 top matches.',
        accordions: [
          {
            title: 'Where do the mentors come from?',
            content:
              'Mentors are employees who come from a wide variety of companies ranging from unicorn startups to Fortune 500 companies with a diverse array of professional experiences.',
          },
          {
            title: 'What is a mentor portfolio?',
            content:
              'Your custom portfolio is selected based on 25 different variables. It represents the professionals who we feel can help you the most.',
          },
          {
            title: 'How much does it cost?',
            content:
              'The program is completely free for you. We wanted to have the greatest impact possible and we didn’t want cost to be a barrier.',
          },
        ],
        carouselContent: {
          content: [
            {
              title: 'Your Portfolio',
              profile: {
                fixed: data.split2p1.childImageSharp.gatsbyImageData,
                industry: 'Tracy Preston',
                name: 'Executive VP',
                uni: 'Neiman Marcus Group',
              },
              targetsSection: {
                customIcon: <ShortBio />,
                heading: 'Short Bio',

                paras: [
                  <p
                    style={{
                      fontSize: '19px',
                      color: 'black',
                      textAlign: 'left',
                      maxWidth: '340px',
                      marginTop: '-10px',
                      padding: '18px 18px 0',
                    }}
                  >
                    As a board-fluent General Counsel, I leverage growth while
                    protecting against loss. I am a businessperson’s lawyer who
                    brings strategic and operational guidance to board agendas
                    and executive decisions.
                  </p>,
                ],
              },
              skillsIcon: true,
              desiredSkills: {
                header: 'Support Areas',
                content: [
                  {
                    skill: 'Business Strategy',
                    icon: <BusinessStrategy />,
                  },
                  {
                    skill: 'Networking',
                    icon: <Networking />,
                  },
                  {
                    skill: 'Communication',
                    icon: <Communication />,
                  },
                ],
              },
            },
            {
              title: 'Your Portfolio',
              profile: {
                fixed: data.split2p2.childImageSharp.gatsbyImageData,
                industry: 'Deanna Jones',
                name: 'Chief HR Officer',
                uni: 'Baker Hughes',
              },
              targetsSection: {
                customIcon: <ShortBio />,
                heading: 'Short Bio',

                paras: [
                  <p
                    style={{
                      fontSize: '19px',
                      color: 'black',
                      textAlign: 'left',
                      maxWidth: '340px',
                      padding: '18px 18px 1.2rem',
                    }}
                  >
                    I am an HR leader with decades of experience. I hope to
                    translate my deep knowledge of recruiting into helping a
                    student gain a better understanding about how they can be
                    successful in their career.
                  </p>,
                ],
              },
              skillsIcon: true,
              desiredSkills: {
                header: 'Support Areas',
                content: [
                  {
                    skill: 'Business Strategy',
                    icon: <BusinessStrategy />,
                  },
                  {
                    skill: 'Networking',
                    icon: <Networking />,
                  },
                  {
                    skill: 'Communication',
                    icon: <Communication />,
                  },
                ],
              },
            },
            {
              title: 'Your Portfolio',
              profile: {
                fixed: data.split2p3.childImageSharp.gatsbyImageData,
                industry: 'Healthcare Manager',
                name: 'Kelsey Cavanaugh',
                uni: 'Protiviti',
              },
              targetsSection: {
                customIcon: <ShortBio />,
                heading: 'Short Bio',

                paras: [
                  <p
                    style={{
                      color: 'black',
                      fontSize: '19px',
                      textAlign: 'left',
                      maxWidth: '340px',
                      paddingBottom: '1.2rem',
                    }}
                  >
                    I am a manager in Protiviti's healthcare industry practice.
                    I focuses on facility and physician settings, utilizing data
                    analytics to identify areas of improvement throughout the
                    revenue cycle.
                  </p>,
                ],
              },
              skillsIcon: true,
              desiredSkills: {
                header: 'Support Areas',
                content: [
                  {
                    skill: 'Business Strategy',
                    icon: <BusinessStrategy />,
                  },
                  {
                    skill: 'Networking',
                    icon: <Networking />,
                  },
                  {
                    skill: 'Communication',
                    icon: <Communication />,
                  },
                ],
              },
            },
          ],
        },
      },
      split3: {
        accordions: [
          {
            title: 'How do you choose my mentor?',
            content:
              'We combine your portfolio rankings with the portfolio rankings of the other mentors/mentees and then optimize who fits best with whom.',
          },
          {
            title: "What if I don't like my selection?",
            content:
              'We encourage you to try to work with your mentor, but sometimes the connection is not there, so just contact us and we will get you set up with another mentor (limits apply).',
          },
          {
            title: 'What are the commitments?',
            content:
              'As a mentee, you are required to come prepared to each session and meet with your mentor once a month during a Season.',
          },
        ],
        graphic: {
          fluid: data.split3a.childImageSharp.gatsbyImageData,
          caption: 'Tracy Preston, Neiman Marcus Group',
        },
      },
      statRowsImages: [
        {
          fluid: data.larry.childImageSharp.gatsbyImageData,
          caption: 'Larry Deutsch, DVK',
        },
        {
          fluid: data.kelsey.childImageSharp.gatsbyImageData,
          caption: 'Kelsey Cavanaugh, Protiviti',
        },
      ],
      statRows: [
        {
          stat: '10k+',
          para: {
            head: 'Professionals',
            text:
              'Thousands of professionals are waiting to help you successfully take the next step.',
          },
        },
        {
          stat: '4-6',
          para: {
            head: 'Potential Matches',
            text:
              'We have partnered with companies from a diverse set of industries to ensure we can help you regardless of your major.',
          },
        },
        {
          stat: '1',
          para: {
            head: 'Mentor',
            text:
              'We find you the perfect match. And you can have multiple matches over your college career.',
          },
        },
      ],
      arrowGraphic: {
        headMax: '548px',
        head: 'Mentors meet with you throughout a four month Season',
        leftP: {
          head: 'Learn from top industry professionals',
          para:
            'Gain the insight and advice you need to help you determine where you want to go and secure your first job.',
        },
        rightP: {
          head: 'Paths to guide your discussions',
          para:
            'We built a series of guides to support your discussions, laying the foundation for the relationship to thrive.',
          rowGraphic: data.arrowRow.childImageSharp.gatsbyImageData,
        },
        mainG: data.arrowGMain.childImageSharp.gatsbyImageData,
      },
      split4: {
        headerText: 'Just one hour a month can change your life',
        headMax: '640px',
        subHead: 'See & Feel the Impact',
        quoteText:
          'Working with my mentor completely changed my decision on what I wanted to do. Without their support, I could not have made the change,  but looking back on it, I am so happy that I did.',
        profile: {
          img: data.antonS.childImageSharp.gatsbyImageData,
          name: 'Anton S.',
        },
        graphic: data.split4Main.childImageSharp.gatsbyImageData,
        accordions: [
          {
            title: 'What happens at the end of a Season?',
            content:
              'At the end of a Season, you have the option of continuing with your match or requesting another match.',
          },
          {
            title: 'What if I am too busy next Season?',
            content:
              'We know things can get hectic with classes which is why we have created an option for you to take a Season or two off whenever you need.',
          },
          {
            title: 'Can I stay connected with my mentor after the program?',
            content:
              'Yes. We hope you build a long term bond with your mentor and that they can help you for many years to come.',
          },
        ],
      },
      last: {
        headerText: 'Meet some incredible people along the way...',
        maxWidth: '700px',
        fluid: withArtDirection(getImage(data.last), [
          {
            media: `(max-width: 500px)`,
            image: getImage(data.last_mobile),
            height: data.last_mobile.childImageSharp.gatsbyImageData.height,
          },
          {
            media: `(max-width: 1200px)`,
            image: getImage(data.last_tablet),
            height: data.last_tablet.childImageSharp.gatsbyImageData.height,
          },
        ]),
      },
    },
    footer: {
      head: `Are you ready to to start building your personal board of directors?`,
      headBgColor: '#441779',
      buttons: (
        <CtaButton
          slug="/students/downloads/"
          bgColor="white"
          textColor="#441779"
        >
          Get Started
        </CtaButton>
      ),
    },
  }
  return (
    <Layout pageData={pageData}>
      <Seo title="How it Works - Students" />
      <HowItWorks {...pageData.howItWorks} />
    </Layout>
  )
}

export default HowItWorksPage
