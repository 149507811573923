import React from 'react'
import { graphql } from 'gatsby'

// Shared UI
import {
  CtaButton,
  HeaderBlock,
  SlideTrifold,
  SplitScreen,
  OverlapBlock,
  OverlapHero,
  Showcase,
} from 'shared-ui'

// Features
import Layout from '../../features/Layout'
import SEO from '../../features/Seo'

// Icons
import Cycle from '../../assets/cycle.svg'
import Page from '../../assets/page.svg'
import Bullseye from '../../assets/bullseye.svg'
import BotRight from '../../assets/uni-bottom-right.svg'
import BotLeft from '../../assets/uni-bottom-left.svg'
import Right from '../../assets/uni-right.svg'
import TopRight from '../../assets/uni-top-right.svg'

export const query = graphql`
  query UniPageQuery {
    hero: file(relativePath: { eq: "uniHero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 373, quality: 100, layout: CONSTRAINED)
      }
    }
    g1: file(relativePath: { eq: "uniG1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 637, quality: 100, layout: CONSTRAINED)
      }
    }
    g3: file(relativePath: { eq: "uniG3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 564, quality: 100, layout: CONSTRAINED)
      }
    }
    g4: file(relativePath: { eq: "uniG4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670, quality: 100, layout: CONSTRAINED)
      }
    }
    g5: file(relativePath: { eq: "uniG5.png" }) {
      childImageSharp {
        gatsbyImageData(width: 523, quality: 100, layout: CONSTRAINED)
      }
    }
    showcase1: file(relativePath: { eq: "showcase1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
      }
    }
    showcase2: file(relativePath: { eq: "showcase2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
      }
    }
    showcase3: file(relativePath: { eq: "showcase3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
      }
    }
    uniHero2: file(relativePath: { eq: "overlapHero3.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    overlapProfile: file(relativePath: { eq: "saraiProfile.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 81, quality: 100, layout: FIXED)
      }
    }
  }
`

const UniversitiesPage = ({ data }) => {
  const uniPageData = {
    hero: {
      pad: '3rem 2rem 0 2rem',
      max: '395px',
      bgColor: '#f9faff',
      innerMax: '1200px',
      headMax: '560px',
      slug: '/contact/',
      mobileBar: {
        text: 'Want to Learn More?  |  Click Here',
        to: '/contact/',
      },
      heroText: 'Building professional connections in a work from home world',
      subText: (
        <p>
          The entire process around how a student connects to professionals
          needs to be rethought in the COVID era.
        </p>
      ),
      graphic: data.hero.childImageSharp.gatsbyImageData,
      vectors: [
        {
          vector: <BotRight />,
          styles: {
            bottom: '-2px',
            right: '0',
            position: 'absolute',
            zIndex: '4',
          },
        },
        {
          vector: <BotLeft />,
          styles: { bottom: '0', left: '0', position: 'absolute' },
        },
        {
          vector: <TopRight />,
          styles: { top: '0', right: '0', position: 'absolute' },
        },
        {
          vector: <Right />,
          styles: { bottom: '0', right: '0', position: 'absolute' },
        },
      ],
    },
    trifold1: {
      cards: [
        {
          icon: <Page />,
          head: `Students Register`,
          sub: `We learn about what they want to achieve through the program`,
        },
        {
          icon: <Bullseye />,
          head: `ID Match`,
          sub: `We match your students with up to five potential mentors`,
        },
        {
          icon: <Cycle />,
          head: `Start the Mentorship`,
          subMax: '360px',
          sub: `Students and their mentors are connected for a four month guided program`,
        },
      ],
    },
    showcase1: {
      cards: [
        {
          image: data.showcase1.childImageSharp.gatsbyImageData,
          name: `Kelsey Cavanaugh`,
          role: `Healthcare Manager at Protiviti`,
          headPhrase: 'Looking to help with:',
          options: [
            `Career Planning`,
            `Effective Networking`,
            `Interview Strategies`,
          ],
        },
        {
          image: data.showcase2.childImageSharp.gatsbyImageData,
          name: `Tracy Preston`,
          role: `Executive VP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [`Building Personal Brand`, `Career Advice`, `Networking`],
        },
        {
          image: data.showcase3.childImageSharp.gatsbyImageData,
          name: `Stefanie Tsen`,
          role: `SVP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [
            `Understanding Marketing`,
            `Inspiring Others`,
            `Networking for your Future`,
          ],
        },
      ],
    },
    footer: {
      head: `Give your students a leg up `,
      sub: `We partner with universities from across the country to bring mentors to their students`,
      buttons: (
        <CtaButton bgColor="white" inverse slug="/contact/">
          Schedule a Demo Today
        </CtaButton>
      ),
      subMax: 700,
    },
  }
  return (
    <Layout pageData={uniPageData}>
      <SEO title="Universities" />

      <OverlapBlock
        head={`How students get their jobs will change dramatically`}
        sub="Without in-person internships and on-campus events that provided a way for underserved students to build networks, a student's ability to secure full time employment after school is limited. The 100k Mentor Program rebuilds these networks in a digital way and through these one-on-one relationships helps students secure their futures."
        buttons={<CtaButton slug="/contact/">Schedule a Demo Today</CtaButton>}
        graphic={data.g1.childImageSharp.gatsbyImageData}
        maxWidth={636}
        maxHeight={607}
        rightPad="80px"
        imgMargin="40px 0 40px -24px"
      />

      <HeaderBlock headerText="How does it work?" pad="10rem 0 3rem 0" />

      <SlideTrifold cards={uniPageData.trifold1.cards} />

      <div
        style={{
          width: `100%`,
          display: `flex`,
          justifyContent: `center`,
          paddingTop: `3rem`,
        }}
      >
        <CtaButton slug="/contact/">Get Started</CtaButton>
      </div>

      <HeaderBlock
        headerText="With just one hour a month, a mentor can change a student’s life"
        pad="12rem 0 3rem 0"
        headMax={1050}
      />

      <OverlapHero
        hero={data.uniHero2.childImageSharp.gatsbyImageData}
        profileImg={data.overlapProfile.childImageSharp.gatsbyImageData}
        quote="“Over the last 4 months, my mentor helped me identify my career path and really taught me how to network. It changed my perspective on how to get my next job.”"
        profileName="Sarai Flores, Penn State University"
        role="100k Mentor Program Participant"
      />
      <HeaderBlock
        pad="5rem 0 5.8rem 0"
        headerText="Powerful software built for students, by students"
      />

      <SplitScreen
        first
        head={'Structured career guidance and advice'}
        sub="The 100k Mentor Program connects professionals from companies across the country with students from underrepresented backgrounds to act as virtual mentors. Mentees are guided through the app’s various Paths, which are structured sessions that ensure both parties are prepared and successful."
        graphic={data.g3.childImageSharp.gatsbyImageData}
        gWidth={564}
        gHeight={735}
        textMax="480px"
        pad="2rem 2rem 6rem"
      />

      <SplitScreen
        reverse
        head={'Diversify your \n students’ exposure'}
        sub="The 100k Mentor Program allows a university to expand beyond their own alumni network and offer its students connections to thousands of professionals. When combined with the university’s alumni, these relationships will be a powerful support tool to help students secure their first jobs."
        graphic={data.g4.childImageSharp.gatsbyImageData}
        gWidth={600}
        gHeight={600}
        innerMax="1060px"
      />

      <SplitScreen
        head={'Support students in \n a variety of fields \n and interests'}
        sub="The 100k Mentor Program is not limited by geography or any particular industry. Students will have the opportunity to interact with professionals across the country and in a variety of different industries. This program is built to give students the space to explore. Once they have identified their next destination, the program allows them to actively cultivate a network of professionals who can help guide them towards their goal."
        graphic={data.g5.childImageSharp.gatsbyImageData}
        gWidth={523}
        gHeight={751}
        pad="8rem 23px 12rem"
        textMax="480px"
        innerMax="1060px"
      />

      <HeaderBlock
        headerText={`Our Mentors: \n Professionals dedicated to supporting the next generation`}
        pad="0 0 2.4rem 0"
        headMax={900}
      />

      <Showcase
        content={uniPageData.showcase1.cards}
        headPhrase="Looking to help with:"
      />
    </Layout>
  )
}

export default UniversitiesPage
