import React from 'react'

import { HeaderBlock, CtaButton } from 'shared-ui'

import Layout from '../features/Layout'

const NotFoundPage = () => {
  const notFoundData = {
    noSupporters: true,
    footer: {
      head: `Did you find a bug?`,
      sub: `Navigate to the contact form with the button below!`,
      buttons: (
        <CtaButton inverse slug={'/contact/'}>
          Contact Us
        </CtaButton>
      ),
    },
  }
  return (
    <Layout pageData={notFoundData}>
      <HeaderBlock
        pad="12rem 2rem 10rem"
        headerText="Oops! this doesn't exist..."
        subText={
          <p style={{ marginBottom: `1rem` }}>
            Click below to get back to main content.
          </p>
        }
      >
        <CtaButton>{`Go Back Home`}</CtaButton>
      </HeaderBlock>
    </Layout>
  )
}

export default NotFoundPage
