import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { v4 as uuidv4 } from 'uuid'
import { useStaticQuery, graphql } from 'gatsby'
import {
  FoundersOuter,
  FoundersWrapper,
  FoundersColumn,
  Brand,
} from './Founders.styles'

const Founders = () => {
  const foundersData = useStaticQuery(graphql`
    query FoundersQuery {
      company1: file(relativePath: { eq: "company1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 260, layout: FIXED)
        }
      }
      brand1: file(relativePath: { eq: "brand1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 104, layout: FIXED)
        }
      }
      company2: file(relativePath: { eq: "company2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 260, layout: FIXED)
        }
      }
      brand2: file(relativePath: { eq: "brand2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 90, layout: FIXED)
        }
      }
      company3: file(relativePath: { eq: "company3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 260, layout: FIXED)
        }
      }
      brand3: file(relativePath: { eq: "brand3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 84, layout: FIXED)
        }
      }
      company4: file(relativePath: { eq: "company4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 260, layout: FIXED)
        }
      }
      brand4: file(relativePath: { eq: "brand4.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: FIXED)
        }
      }
    }
  `)

  const founders = [
    {
      companyName: 'Protiviti',
      img: foundersData.company1.childImageSharp.gatsbyImageData,
      brand: foundersData.brand1.childImageSharp.gatsbyImageData,
      para:
        '“Protiviti is thrilled to join ProMazo and provide our people with the opportunity to mentor and form lasting relationships with underrepresented college students. [The] 100K Mentors initiative is a great way to share those values with others.”',
      name: 'Susan Haseley',
      title: 'VP, Global Diversity and Inclusion',
    },
    {
      companyName: 'Neiman Marcus Group',
      img: foundersData.company2.childImageSharp.gatsbyImageData,
      brand: foundersData.brand2.childImageSharp.gatsbyImageData,
      para:
        '“Mentoring leaders of tomorrow not only develops the future workforce, it further engages our company’s associates today. The virtuous cycle of mentorship is a priceless gift whose impact can change a life, a family, and ultimately our community.”',
      name: 'Eric Severson',
      title: 'Chief People Officer',
    },
    {
      companyName: 'Baker Hughes',
      img: foundersData.company3.childImageSharp.gatsbyImageData,
      brand: foundersData.brand3.childImageSharp.gatsbyImageData,
      para:
        '“Mentoring leaders of tomorrow not only develops the future workforce, it further engages our company’s associates today. The virtuous cycle of mentorship is a priceless gift whose impact can change a life, a family, and ultimately our community.”',
      name: 'Deanna Jones',
      title: 'Chief Human Resource Officer',
    },
    {
      companyName: 'Goodwill',
      img: foundersData.company4.childImageSharp.gatsbyImageData,
      brand: foundersData.brand4.childImageSharp.gatsbyImageData,
      para:
        '“We at Goodwill® believe everyone deserves an opportunity to thrive. To transform our vision into reality, we need to contribute to each other’s development. Mentoring is one way to ensure all aspiring leaders build social capital and advance their careers.”',
      name: 'Wendi Copeland',
      title: 'Chief Mission and Partnership Officer',
    },
  ]
  return (
    <FoundersOuter>
      <h2 className="founders-title">
        Hear from some of our founding partners
      </h2>
      <FoundersWrapper>
        {founders.map((founder) => {
          return (
            <FoundersColumn key={uuidv4()}>
              <GatsbyImage
                image={founder.img}
                style={{ borderRadius: '4px' }}
                alt={founder.name}
              />
              <Brand>
                <GatsbyImage image={founder.brand} alt={founder.companyName} />
              </Brand>
              <h3>{founder.companyName}</h3>
              <h4>{founder.brandName}</h4>
              <p className="para">{founder.para}</p>
              <p className="name">{founder.name}</p>
              <p className="title">{founder.title}</p>
            </FoundersColumn>
          )
        })}
      </FoundersWrapper>
    </FoundersOuter>
  )
}

export default Founders
