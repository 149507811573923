import React from 'react'
import Layout from '../features/Layout'
import Faq from '../features/Faq'
import Seo from '../features/Seo'

import { CtaButton } from 'shared-ui'

const FaqPage = ({ pageContext }) => {
  const faqPageData = {
    noSupporters: true,
    footer: {
      head: pageContext.footer.head,
      sub: pageContext.footer.sub,
      buttons: (
        <CtaButton
          bgColor="white"
          inverse
          slug={pageContext.button.slug}
          outLink={pageContext.button.href}
        >
          {pageContext.button.text}
        </CtaButton>
      ),
    },
  }

  const { title, questions } = pageContext

  return (
    <Layout pageData={faqPageData}>
      <Seo title={`${title} FAQs`} />
      <Faq questions={questions} title={title} />
    </Layout>
  )
}

export default FaqPage
