import styled from 'styled-components'

export const FoundersOuter = styled.section`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .founders-title {
    color: white;
    font-size: 48px;

    margin-bottom: 42px;
    text-align: center;
    @media only screen and (max-width: 1200px) {
      font-size: 40px !important;
    }
    @media only screen and (max-width: 900px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @media only screen and (max-width: 700px) {
      font-size: 40px !important;
    }
  }
  padding-bottom: 72px;
  padding: 38px 0 72px;
`

export const FoundersWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1400px) {
    max-width: 1000px;
  }
  @media only screen and (max-width: 895px) {
    max-width: 700px;
  }
`

export const FoundersColumn = styled.div`
  flex: 1;
  color: white;
  text-align: center;
  position: relative;
  min-width: 305px;
  max-width: 300px;
  margin: 2rem 5px;
  min-height: 550px;
  align-items: center;
  z-index: 1;
  @media only screen and (max-width: 636px) {
    margin-bottom: 4rem;
  }
  .para {
    font-size: 16px;
    font-weight: 400;
    margin: 0 12px 22px;
  }

  .name {
    font-size: 19px;
    font-weight: 500;
  }

  > h3 {
    font-size: 29px;
    margin-bottom: 6px;
    font-weight: 500;
  }

  > h4 {
    font-size: 19px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
`

export const Brand = styled.div`
  margin-top: -72px;
  margin-bottom: 36px;
  position: relative;
  background-color: white;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  z-index: 5;
  margin: -72px auto 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`
