import styled from 'styled-components'

const width = '100%'
const height = '550px'

export const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: ${width};
  min-height: ${height};
  margin: 0 auto;
  @media only screen and (max-width: 800px) {
    min-height: 800px;
  }
`
export const Children = styled.div`
  width: ${width};
  position: relative;
  min-height: ${height};
  @media only screen and (max-width: 800px) {
    min-height: 800px;
  }
`
export const Dot = styled.span`
  font-size: 2.4rem;
  font-family: Arial;
  cursor: pointer;
  text-shadow: 1px 1px 1px #fff;
  user-select: none;
  z-index: 15;
`
export const Dots = styled.span`
  display flex;
  position: absolute;
  align-items: flex-end;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grey1};
  bottom: 1.8rem;
  width: ${width};
  z-index: 14;
`
