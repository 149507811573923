import styled from 'styled-components'

export const SignatoriesWrapper = styled.section`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  max-width: 1050px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding-bottom: 4rem;
`
export const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  > div {
    width: 100%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0.5rem 1rem;
  padding: 1rem;
  &.l1 {
    max-width: 310px;
  }
  &.l2 {
    max-width: 300px;
  }
  &.l3 {
    max-width: 340px;
  }
  &.l4 {
    max-width: 280px;
  }
  &.l5 {
    max-width: 240px;
  }
  &.l6 {
    max-width: 180px;
  }
  &.l7 {
    max-width: 200px;
  }
  &.l8 {
    max-width: 200px;
  }
  &.l9 {
    max-width: 180px;
  }
`
