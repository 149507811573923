import React from 'react'
import BgImg from 'gatsby-background-image'
import styled from 'styled-components'
import { convertToBgImage } from 'gbimage-bridge'
import { HeaderHat } from '../HeaderBlock/HeaderBlock.styles'
import { TextBlock } from './FullWidthSplit.styles'

const FullWidthSplit = ({
  graphic,
  head,
  sub,
  bgColor,
  breakpoint,
  bgPosition,
  bgSize,
  mobileBreak,
  textMax,
}) => {
  const bgImage = convertToBgImage(graphic)
  return (
    <div
      style={{
        backgroundColor: bgColor || `rgb(229,229,229)`,
        width: `100%`,
      }}
    >
      <StyledBgImg
        bgPosition={bgPosition}
        bgSize={bgSize}
        fluid={bgImage.fluid}
        breakpoint={breakpoint}
        mobileBreak={mobileBreak}
      >
        <TextBlock
          breakpoint={breakpoint}
          mobileBreak={mobileBreak}
          textMax={textMax}
        >
          <HeaderHat />
          <h3>{head}</h3>
          <div className="paras">{sub}</div>
        </TextBlock>
      </StyledBgImg>
    </div>
  )
}

const StyledBgImg = styled(BgImg)`
  margin: 0 auto;
  max-width: 1400px;
  background-repeat: no-repeat, no-repeat, no-repeat;
  height: 750px;
  display: flex;
  overflow: visible;
  background-size: ${({ bgSize }) => bgSize};

  background-position: ${({ bgPosition }) => bgPosition} !important;
  @media only screen and (max-width: ${({ breakpoint }) => breakpoint}px) {
  }
  @media only screen and (max-width: ${({ mobileBreak }) => mobileBreak}px) {
    height: auto;
    background-color: #e5e5e5;
    background-image: none !important;
    &:before,
    &:after {
      background-image: none !important;
    }
  }
`

export default FullWidthSplit
