import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { v4 as uuidv4 } from 'uuid'

// Shared UI
import {
  HeaderBlock,
  CtaButton,
  OverlapBlock,
  SlideTrifold,
  ListItem,
  StudentCards,
  TextReplace,
} from 'shared-ui'

// Features
import Layout from '../../features/Layout'
import SEO from '../../features/Seo'

// Primitives
import FullWidthSplit from '../../primitives/FullWidthSplit'

// Icon
import {
  Woman,
  Man,
  Building,
  MagnifyDrkLg,
  Diploma,
} from '../../primitives/Icons'

import TopRight from '../../assets/about-top-right.svg'
import BotRight from '../../assets/about-bottom-right.svg'
import TopLeft from '../../assets/about-top-left.svg'

// Image Data
export const query = graphql`
  query AboutPageQuery {
    hero: file(relativePath: { eq: "aboutHero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 490, quality: 100, layout: CONSTRAINED)
      }
    }
    g1: file(relativePath: { eq: "aboutG1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
      }
    }
    g2: file(relativePath: { eq: "aboutG2_mobile.png" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
      }
    }
    g2_desktop: file(relativePath: { eq: "aboutG2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    g3: file(relativePath: { eq: "aboutG3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    g7: file(relativePath: { eq: "aboutG7.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card1: file(relativePath: { eq: "studentCard1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 501, quality: 100, layout: CONSTRAINED)
      }
    }
    card2: file(relativePath: { eq: "studentCard2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 501, quality: 100, layout: CONSTRAINED)
      }
    }
    card3: file(relativePath: { eq: "studentCard3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 501, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

// Icons Array
const iconsArr = [
  <Woman key={uuidv4()} style={{ fill: `#f84f68` }} />,
  <Man key={uuidv4()} style={{ fill: `#f84f68` }} />,
  <Woman key={uuidv4()} style={{ fill: `#f84f68` }} />,
  <Man key={uuidv4()} style={{ fill: `#f84f68` }} />,
  <Woman key={uuidv4()} style={{ fill: `#f84f68` }} />,
  <Man key={uuidv4()} style={{ fill: `#f84f68` }} />,
  <Woman key={uuidv4()} style={{ fill: `#f84f68` }} />,
  <Man key={uuidv4()} style={{ fill: `black` }} />,
  <Woman key={uuidv4()} style={{ fill: `black` }} />,
  <Man key={uuidv4()} style={{ fill: `black` }} />,
]

const AboutPage = ({ data }) => {
  const aboutPageData = {
    noSupporters: true,
    hero: {
      pad: '5rem 2rem 0',
      max: '490px',
      bgColor: '#f9faff',
      innerMax: '1300px',
      headMax: '600px',
      mobileBar: {
        text: 'Join the Challenge  |  Click Here',
        to: '/mentors/downloads/',
      },
      heroText:
        'We will not take a step backwards on the path to equality because of the coronavirus',
      subtext: (
        <p>
          Showcase your values and impact to your customers, employees and the
          community in a social, scalable, and digital way.
        </p>
      ),
      graphic: data.hero.childImageSharp.gatsbyImageData,
      vectors: [
        {
          vector: <BotRight />,
          styles: {
            bottom: '0',
            right: '0',
            position: 'absolute',
          },
        },
        {
          vector: <TopRight />,
          styles: {
            top: '-2px',
            right: '0px',
            position: 'absolute',
            zIndex: '3',
          },
        },
        {
          vector: <TopLeft />,
          styles: { top: '0', left: '0', position: 'absolute' },
        },
        {
          vector: null,
          styles: {
            bottom: '-26px',
            right: '0',
            position: 'absolute',
            background: `linear-gradient(96deg, rgba(248,101,122,1) 0%, rgba(75,52,237,1) 100%)`,
            width: '100%',
            height: '90px',
            transform: `rotate(-1.6deg)`,
          },
        },
      ],
    },
    trifold1: {
      cards: [
        {
          icon: (
            <p style={{ fontSize: `6rem`, paddingBottom: `1.5rem` }}>40%</p>
          ),
          sub: `of college graduates take positions that don’t require their degree.`,
        },
        {
          icon: (
            <p style={{ fontSize: `6rem`, paddingBottom: `1.5rem` }}>75%</p>
          ),
          sub: `of these graduates will be in the same spot 10 years later.`,
        },
        {
          icon: (
            <p style={{ fontSize: `6rem`, paddingBottom: `1.5rem` }}>$10,000</p>
          ),
          sub: `first year salary loss compared to students who are fully employed`,
        },
      ],
    },
    studentCards: [
      {
        name: 'Nicholas P.',
        icon: data.card1.childImageSharp.gatsbyImageData,
        para:
          'He is a Data Scientist and Algorithm Engineer with a Bachelors degree in Mathematics from the University of Rochester.',
      },
      {
        name: 'Juliette M.',
        icon: data.card2.childImageSharp.gatsbyImageData,
        para:
          'She is a UX/UI Designer and Marketing Strategist with a degree in Art and Marketing from the University of California, Berkeley.',
      },
      {
        name: 'Louis D.',
        icon: data.card3.childImageSharp.gatsbyImageData,
        para:
          'He is an Engineering Project Manager with a Doctorate of Philosophy in Computer Science from the University of Notre Dame.',
      },
    ],
    textReplace: {
      desktop: data.g2_desktop.childImageSharp.gatsbyImageData,
      mobile: data.g2.childImageSharp.gatsbyImageData,
    },
    footer: {
      head: `Become a force for positive social change and preregister for the 100k Mentor Challenge today!`,
      buttons: (
        <CtaButton slug={'/mentors/downloads/'} inverse bgColor="white">
          Sign Up Today
        </CtaButton>
      ),
      headMax: 1200,
    },
  }
  return (
    <Layout pageData={aboutPageData}>
      <SEO title="About Us" />
      <HeaderBlock
        pad="8rem 1.8rem"
        headerText="Be part of the movement to level the playing field"
        subText={
          <p>
            Equal access to professional opportunities may start with helping
            them get the right education, but it ends with who they know. Even
            with all our work to elimnate hiring bias, the fact remains that
            companies and people hire the people they know/are exposed to. In
            short, they hire through their networks.
          </p>
        }
        subMax={1000}
      />
      <OverlapBlock
        head="A 21st century mentorship program"
        sub="We built a modern mentorship tool that will ensure underrepresented students can build these crucial networks during a time when all of their traditional ways to do so have evaporated. Together in partnership with forward thinking companies and their employees, we can ensure the fight to give everyone equal access to high quality jobs becomes a reality."
        graphic={data.g1.childImageSharp.gatsbyImageData}
        maxWidth={570}
        maxHeight={570}
        imgMargin="0 0 0 32px"
        noPad
        rightPad="40px"
        bgColor="#efefef"
      />
      <HeaderBlock
        graphic={<div className="people-graphic">{iconsArr}</div>}
        pad="10rem 1.8rem 4rem"
        headerText="7 out of 10 jobs come from networking"
        position="center"
      />
      <HeaderBlock
        pad="3rem 1.8rem 6rem!important"
        headerText="Companies, universities and recruiting are going remote"
        noHat
        headMax={900}
        graphic={
          <div className="about-trifold-icons">
            <Diploma />
            <Building />
            <MagnifyDrkLg />
          </div>
        }
        subText={
          <p>
            But many students from underrepresented backgrounds rely on these in
            person connections to buld their networks and do not have networks
            to fall back on, leaving them vulnerable to under or unemployement
          </p>
        }
        subMax={800}
      />

      <HeaderBlock
        pad="4rem 0 3rem 0"
        headMax={1000}
        headerText="Why is a person’s first job so important to the rest of their lives?"
      />

      <SlideTrifold cards={aboutPageData.trifold1.cards} />

      <HeaderBlock
        pad="4.8rem 0 6rem"
        subMax={1000}
        subText={
          <p style={{ fontWeight: `500`, fontSize: '2.5rem' }}>
            This number is set to grow. And it will impact students from
            disadvantaged backgrounds most. Quite simply we risk a Lost
            Generation if we do not fix this.
          </p>
        }
      />
      <HeaderBlock
        inverse
        pad="4rem 23px 2rem"
        headerText="Our goal is to provide 100,000 students from underrepresented backgrounds"
      >
        <ol style={{ maxWidth: '900px' }}>
          <ListItem
            liColor="#38175b"
            inverse
            paragraph="Guidance from professionals to help students with their career
            direction"
          />
          <ListItem
            liColor="#38175b"
            inverse
            paragraph="The chance to build their networks because the pandemic has
            eliminated the traditional ways to do so"
          />
          <ListItem
            liColor="#38175b"
            inverse
            paragraph="Professional sponsorship and empowered mentors who can flag them for open positions"
          />
        </ol>
      </HeaderBlock>
      <HeaderBlock
        pad="8rem 2rem"
        headerText="Coming together in the midst of a global pandemic"
        graphic={
          <div className="about-text-trifold">
            <div>
              <p className="top-text">Over</p>
              <p className="middle-text">50</p>
              <p className="bot-text">Students</p>
            </div>
            <div>
              <p className="top-text">From</p>
              <p className="middle-text">15</p>
              <p className="bot-text">Universities</p>
            </div>
            <div>
              <p className="top-text">During</p>
              <p className="middle-text">1</p>
              <p className="bot-text">Summer</p>
            </div>
          </div>
        }
      >
        <div id="trigger" />
      </HeaderBlock>

      <div
        style={{
          textAlign: `center`,
          background: `linear-gradient(
      180deg,
      rgb(249, 249, 249) 95%,
      white 5%
    )`,
        }}
      >
        <TextReplace
          staticText="To solve for"
          text="a problem"
          newText="an opportunity"
          graphics={aboutPageData.textReplace}
          bgColor={'rgb(249, 249, 249)'}
        />
      </div>

      <FullWidthSplit
        bgColor="white"
        breakpoint={1050}
        mobileBreak={952}
        bgPosition="bottom right"
        bgSize="700px 700px"
        graphic={data.g3.childImageSharp.gatsbyImageData}
        head="Why we built this"
        textMax={'550px'}
        sub={
          <>
            <p>
              The initiative is designed to rise to the challenge of the times.
              We rethought how underrepresented students can have equal access
              to professional opportunities and built it to help these students
              in a digital, post-Covid age.
            </p>
            <p>
              The 100k Mentor Challenge is a place where students from
              underrepresented backgrounds can connect/grow and where
              professionals can dramatically change the lives of everyone they
              touch.
            </p>
          </>
        }
      />

      <HeaderBlock
        pad="10rem 1.8rem 4rem"
        headerText="Meet the students behind the movement"
      />
      <StudentCards studentCardsObjs={aboutPageData.studentCards} />
      <HeaderBlock
        position="center"
        headerText="Our Foundation"
        pad="4.6rem 0 5rem"
        subMax={1000}
        subText={
          <>
            <p style={{ textAlign: `left` }}>
              The ProMazo National Foundation is a 501c(3) organization whose
              mission is to understand where the workplace of the future and
              higher education intersect. There has been too little investment
              in the transition from college to the professional world to set
              our young people up for success, meaning that many of our
              brightest students stumble just when they should be lifting off.
            </p>
            <p style={{ textAlign: `left` }}>
              The ProMazo National Foundation aims to change that. Through
              cutting edge research on how people can find their jobs/the future
              of the workplace, exploring new ways to deliver education that
              makes students more prepared for the next step, and developing
              cutting edge partnerships to deliver new ways of connecting
              industry to their future employees, we are going to fundamentally
              shift the conversation and make sure every college student is able
              to reach their full potential.
            </p>
          </>
        }
      />
      <GatsbyImage
        image={data.g7.childImageSharp.gatsbyImageData}
        style={{ maxHeight: `100vh` }}
        alt="Phone app preview"
      />

      <HeaderBlock
        position="center"
        headerText="About ProMazo"
        pad="4.6rem 0 5rem"
        subMax={1000}
        subText={
          <>
            <p style={{ textAlign: `left` }}>
              ProMazo believes the intellectual firepower of the 19 million
              people attending American universities represents a vast, diverse
              and untapped talent pool. To tap into this incredible group,
              ProMazo has developed a platform and methodology that fuses the
              brightest software developers, machine learning gurus,
              communications experts and designers from universities with
              corporate work teams to solve challenges across a company’s IT,
              Finance, Marketing and Communications departments. Not only can
              companies get work done, but through these remote work
              engagements, ProMazo gives companies access to a new type of
              workforce that is diverse, technically skilled, and adjustable
              without the premiums traditionally associated with a variable
              workforce.
            </p>
            <p className="no-mobile" style={{ textAlign: `left` }}>
              ProMazo is also committed to supporting the next generation of
              talent, because while ability is equally distributed, opportunity
              is not. Through its apprenticeship programs, coaching, software
              tools and work engagements, ProMazo diversifies the distribution
              of quality jobs and helps eliminate the Diploma Paradox (one of
              the causes of the $1.7 trillion student debt crisis). By guiding
              companies away from the traditional recruiting process and the use
              of &quot;biased” evaluation tools (i.e. GPA, Alma Mater,
              Interviews etc), ProMazo provides an avenue for college students
              to showcase their talents while earning their degrees...
              <strong> DEBT FREE</strong>.
            </p>
            <p style={{ textAlign: `left` }}>
              We are supporting our Foundation in this initiative by providing
              all of the underlying technology to operate the program and
              enabling the student teams to quickly get their ideas into the
              field. We are blown away with what they have been able to to
              create in just one summer. We look forward to getting the chance
              to mentor the next generation ourselves and spreading the
              movement!
            </p>
          </>
        }
      />
    </Layout>
  )
}

export default AboutPage
