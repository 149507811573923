import React, { Fragment } from 'react'
import { graphql } from 'gatsby'

// Features
import Layout from '../features/Layout'
import SEO from '../features/Seo'
import Founders from '../features/Founders'

// Shared UI components
import {
  OverlapBlock,
  CtaButton,
  HeaderBlock,
  SlideTrifold,
  SplitScreen,
  Tricolor,
  OverlapHero,
} from 'shared-ui'

import CrossOutIcons from '../primitives/CrossOutIcons'

import LeftGroup from '../assets/landing-left-group.svg'
import RightGroup from '../assets/landing-right-group.svg'
import RightStatic from '../assets/landing-right-static.svg'
import LeftStatic from '../assets/landing-left-static.svg'

// Svgs
import {
  IdeaSm,
  PeopleSm,
  Trophy,
  Dialog,
  Megaphone,
  Cube,
  Upgrade,
  Magnify,
  Showcase,
  Solution,
  Rocket,
  Checklist,
} from '../primitives/Icons'

// Image Data
export const query = graphql`
  query LandingPageQuery {
    landingDesktop: file(relativePath: { eq: "landingHero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 766, quality: 100, layout: FULL_WIDTH)
      }
    }
    landingMobile: file(relativePath: { eq: "landingHeroMobile.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: FULL_WIDTH)
      }
    }
    g1: file(relativePath: { eq: "landingG1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 646, quality: 100, layout: FULL_WIDTH)
      }
    }
    g2: file(relativePath: { eq: "landingG2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 403, quality: 100, layout: FULL_WIDTH)
      }
    }
    g3: file(relativePath: { eq: "landingG3.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    g4: file(relativePath: { eq: "landingG4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 517, quality: 100, layout: FULL_WIDTH)
      }
    }
    g5: file(relativePath: { eq: "landingG5.png" }) {
      childImageSharp {
        gatsbyImageData(width: 583, quality: 100, layout: FULL_WIDTH)
      }
    }
    g6: file(relativePath: { eq: "landingG6.png" }) {
      childImageSharp {
        gatsbyImageData(width: 583, quality: 100, layout: FULL_WIDTH)
      }
    }
    g7: file(relativePath: { eq: "landingG7.png" }) {
      childImageSharp {
        gatsbyImageData(width: 468, quality: 100, layout: FULL_WIDTH)
      }
    }
    overlapHero1: file(relativePath: { eq: "overlapHero1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    overlapProfile: file(relativePath: { eq: "saraiProfile.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 81, quality: 100, layout: FIXED)
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const landingPageData = {
    hero: {
      pad: '9rem 0 0',
      bgColor: '#f9faff',
      innerMax: '1420px',
      headMax: '600px',
      textPad: '4rem 1rem 2rem',
      mobileBar: {
        text: 'Join the Challenge | Click Here',
        to: '/mentors/downloads/',
      },
      max: '780px',
      heroText:
        'Enabling companies to connect with, inspire, and guide the next generation',
      graphic: data.landingDesktop.childImageSharp.gatsbyImageData,
      vectors: [
        {
          vector: <LeftGroup />,
          styles: { bottom: '-3px', left: '0', position: 'absolute' },
        },
        {
          vector: <LeftStatic />,
          styles: { top: '0', left: '0', position: 'absolute' },
        },
        {
          vector: <RightGroup />,
          styles: { top: '0', right: '0', position: 'absolute' },
        },
        {
          vector: <RightStatic />,
          styles: { bottom: '0', right: '0', position: 'absolute' },
        },
      ],
    },
    trifold1: {
      cards: [
        {
          icon: <Checklist />,
          head: `1. Build Your Profile`,
          sub: `Tell us about yourself and what you want to accomplish`,
        },
        {
          icon: <Solution />,
          head: `2. Find Your Match`,
          sub: `Match with someone who shares your professional goals `,
        },
        {
          icon: <Rocket />,
          head: `3. Start Connecting`,
          sub: `Work and grow together during the 4-month mentorship`,
        },
      ],
    },
    tricolor: [
      {
        head: `University Students \n and Scholars`,
        points: [
          {
            icon: <IdeaSm />,
            text:
              'Connect with professionals in the industry you want to join after school',
          },
          {
            icon: <PeopleSm />,
            text: 'Learn what it takes to succeed in the professional world',
          },
          {
            icon: <Trophy />,
            text:
              'Gain important insight, guidance, and support from your mentor',
          },
        ],
      },
      {
        head: `Working \n Professionals`,
        points: [
          {
            icon: <Dialog />,
            text:
              'Take action against systemic inequality and ensure we do not have a lost generation',
          },
          {
            icon: <Megaphone />,
            text:
              'Develop new perspectives you can bring back to your organization',
          },
          {
            icon: <Cube />,
            text:
              'Learn how to effectively communicate with the next generation',
          },
        ],
      },
      {
        head: `Companies and \n Organizations`,
        points: [
          {
            icon: <Upgrade />,
            text:
              'Create a channel to engage your workforce for positive social change',
          },
          {
            icon: <Magnify />,
            text:
              'Showcase your culture and values to your next generation of employees',
          },
          {
            icon: <Showcase />,
            text: 'Attract, identify and engage top candidates at scale',
          },
        ],
      },
    ],
    footer: {
      head: `Become a force for positive social change and register for the 100k Mentor Challenge today!`,
      headMax: 1100,
      buttons: (
        <CtaButton slug="/mentors/downloads/" bgColor="white" inverse>
          Sign Up Today
        </CtaButton>
      ),
    },
  }
  return (
    <Layout pageData={landingPageData}>
      <SEO title="Home" />

      <HeaderBlock
        inverse
        pad="8rem 1.8rem 6rem"
        accent="#f84f68"
        bgColor="#38175b"
        headMax={930}
        headerText={`Our Mission: \n Help 100,000 underrepresented students secure their first job`}
        subText={
          <Fragment>
            <p style={{ textAlign: 'left', color: 'white' }}>
              Due to the coronavirus, internships, on-campus events, and many
              job offers were rescinded. These cuts hit students from
              underrepresented backgrounds especially hard because they often
              use these opportunities to build their networks and secure their
              first job. Ensuring that students land their first job is crucial
              because it impacts their earning potential for years to come. We
              realized that if we did not help rebuild these informal networks,
              incredibly bright students could become under or unemployed,
              risking a lost generation just when the efforts in higher
              education to bring in a more diverse student body have started to
              bear fruit.
            </p>
            <p style={{ textAlign: 'left', color: 'white' }}>
              We are committed to ensuring this does not happen. We will not
              have a <strong>Lost Generation.</strong>
            </p>
            <p
              style={{ textAlign: 'left', color: 'white' }}
              className="no-mobile"
            >
              To ensure we stay true to our mission, the entire platform is
              student-led from the technology behind the app to the
              communications and roll-out strategies employed for the
              initiative. We believe in the next generation, and we want to
              showcase their talents to the world.{' '}
              <strong>We Hope You Will Join Us.</strong>
            </p>
            <div style={{ paddingTop: '.8rem' }} />
          </Fragment>
        }
        subMax={1030}
      >
        <CtaButton slug={'/mentors/downloads/'}>Sign up now</CtaButton>
      </HeaderBlock>

      <HeaderBlock
        pad="8.75rem 3rem"
        headerText="Help the next generation while building connections to your future workforce"
        subText={
          <p className="tablet-text">
            Showcase your values and impact to your customers, employees, and
            the community in a social, scalable, and digital way.
          </p>
        }
        headMax={1200}
        subMax={1230}
      />
      <OverlapBlock
        head="The 100k Mentor Challenge"
        sub={
          <>
            <p>
              A mobilization of companies and professionals across the country
              to support the next generation of leaders.
            </p>
            <p>
              Our first goal is to register and connect 100,000 professionals to
              mentor 100,000 students from underserved communities during the{' '}
              {new Date().getFullYear()}/{new Date().getFullYear() + 1} academic
              year.
            </p>
          </>
        }
        buttons={null}
        graphic={data.g1.childImageSharp.gatsbyImageData}
        maxWidth={600}
        maxHeight={650}
        imgMargin="0 0 0 36px"
        noPad
        bgColor="#f3eff7"
      />

      <div
        style={{
          width: `100%`,
          display: `flex`,
          justifyContent: `center`,
          paddingTop: `3.5rem`,
        }}
      >
        <CtaButton slug={'/mentors/downloads/'}>Sign Up Now</CtaButton>
      </div>

      <HeaderBlock
        headerText="How does the program work?"
        pad="10rem 0 3rem 0"
      />

      <SlideTrifold cards={landingPageData.trifold1.cards} />

      <div
        style={{
          width: `100%`,
          display: `flex`,
          justifyContent: `center`,
          paddingTop: `3.5rem`,
          paddingBottom: `8rem`,
        }}
      >
        <div id="trigger" />
        <CtaButton slug={'/mentors/downloads/'}>Sign Up Now</CtaButton>
      </div>

      <HeaderBlock
        graphic={
          <div style={{ display: `flex` }}>
            <CrossOutIcons />
          </div>
        }
        secondary
        pad="4.8rem 2rem"
      />

      <HeaderBlock
        headerText="A Challenge & An Opportunity"
        subText={
          <p>
            With the COVID pandemic causing all of the rules to be rewritten,
            this period in history will mark one of the few moments where nearly
            every facet of the recruiting process is being reimagined. As
            student leaders, while these may be uncertain times, we are excited
            by the challenge because it is an opportunity for us to help
            contribute to a better, sustainable, and more equitable system.
          </p>
        }
        gWidth={403}
        gHeight={770}
        pad="8rem 23px"
        full
      />

      <SplitScreen
        graphic={data.g2.childImageSharp.gatsbyImageData}
        gWidth={403}
        gHeight={770}
        pad="0 23px 8rem"
        innerMax="1130px"
        head="Digital First & Automated"
        textMax="560px"
        first
        sub={`
            We wanted to ensure the program was accessible to all no matter
            where they were located and that it utilized the platform where Gen Z
            lives: their phones. Recognizing that both students and mentors
            would have demands on their lives, we empower both sides to engage
            with each other on their schedules and automated much of the
            matching process so no one has to spend time sifting through
            thousands of profiles to find the perfect match.
          `}
      />
      <SplitScreen
        textMax="560px"
        reverse
        head="Reconstructing & Reimagining Networking"
        sub="We are facing a new reality. The traditional ways we build bonds with people through in person interactions are going to be severely limited for the next several years, yet building these relationships between people will still be crucial to a person’s success. So we reimagined how students from underserved communities can extend their networks and connect with inspiring professionals who they may not otherwise be able to access."
        graphic={data.g4.childImageSharp.gatsbyImageData}
        gWidth={517}
        gHeight={692}
        innerMax="1150px"
        pad="8rem 23px 12rem"
      />

      <Founders />

      <HeaderBlock
        headerText="An initiative that can engage your whole workforce"
        pad="10rem 0 8rem"
      />

      <SplitScreen
        head="Providing role models, guides, and a network"
        sub="Changing someone’s life does not require money or special training. Mentors simply have to draw on their experiences, be sounding boards for the mentees, and commit to just an hour a month."
        graphic={data.g5.childImageSharp.gatsbyImageData}
        gWidth={445}
        gHeight={573}
        textMax="500px"
        pad="0 23px 8rem"
        innerMax="1100px"
        first
      />
      <SplitScreen
        reverse
        textMax="460px"
        innerMax="1080px"
        innerMiddle="7%"
        head="A mobile community of mentors. Anytime. Anywhere."
        sub="Our app automatically matches people based on shared interests and provides structured guides so conversations can flow quickly and easily. Because we know schedules are busy, the app empowers mentors and mentees to connect on their schedules."
        graphic={data.g6.childImageSharp.gatsbyImageData}
        gWidth={533}
        gHeight={752}
      />
      <SplitScreen
        textMax="520px"
        innerMax="1080px"
        innerMiddle="12%"
        head="Activate your entire workforce and show your company’s values"
        sub="The 100k Mentor Challenge is a channel to harness the energy of your employees, unite your workforce behind a common mission, and take action to ensure the next generation of leaders are diverse."
        graphic={data.g7.childImageSharp.gatsbyImageData}
        gWidth={468}
        gHeight={701}
      />

      <HeaderBlock
        headerText="With just one hour a month, a mentor can change a student’s life"
        pad="4rem 0 3rem 0"
        headMax={1080}
      />

      <OverlapHero
        hero={data.overlapHero1.childImageSharp.gatsbyImageData}
        quote="“Over the last 4 months, my mentor helped me identify my career path and really taught me how to network. It changed my perspective on how to get my next job.”"
        profileImg={data.overlapProfile.childImageSharp.gatsbyImageData}
        profileName="Sarai Flores, Penn State University"
        role="100k Mentor Program Participant"
      />

      <HeaderBlock
        headerText="A unique program that is a win-win-win"
        pad="6rem 0 3rem"
      />
      <Tricolor content={landingPageData.tricolor} />
      <HeaderBlock pad="3rem" />
    </Layout>
  )
}

export default IndexPage
