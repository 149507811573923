import styled from 'styled-components'

export const ParallaxOuter = styled.div`
  justify-content: center;
  max-width: 1440px;
  width: 100%;
`

export const ParallaxWrapper = styled.div`
  position: relative;
  @media only screen and (max-width: 500px) {
    padding-bottom: 60px;
  }
  > p {
    font-size: 21px;
    @media only screen and (max-width: 600px) {
      font-size: 19px !important;
    }
  }

  h2 {
    font-size: 48px;
    @media only screen and (max-width: 1200px) {
      font-size: 38px;
    }
    @media only screen and (max-width: 700px) {
      font-size: 28px;
    }
  }

  @-webkit-keyframes fadeOutUp {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-100px);
    }
  }

  @keyframes fadeOutUp {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-100px);
    }
  }

  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @-webkit-keyframes moveUp {
    0% {
      -webkit-transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-220px);
    }
  }

  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-220px);
    }
  }

  @-webkit-keyframes moveUp2 {
    0% {
      opacity: 0;
      -webkit-transform: translateY(0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(-140px);
      @media only sceen and (max-width: 500px) {
        -webkit-transform: translateY(-120px);
      }
    }
  }

  @keyframes moveUp2 {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-140px);
      @media only screen and (max-width: 500px) {
        transform: translateY(-180px);
      }
    }
  }

  @-webkit-keyframes moveDown {
    0% {
      -webkit-transform: translateY(-220px);
    }
    100% {
      -webkit-transform: translateY(0);
    }
  }

  @keyframes moveDown {
    0% {
      transform: translateY(-220px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @-webkit-keyframes moveDown2 {
    0% {
      -webkit-transform: translateY(-120px);
    }
    100% {
      -webkit-transform: translateY(0);
    }
  }

  @keyframes moveDown2 {
    0% {
      opacity: 1;
      transform: translateY(-120px);
    }
    100% {
      opacity: 0;
      transform: translateY(0);
    }
  }

  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    animation-duration: 0.8s;
    animation-fill-mode: both;
  }
  .fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
    animation-duration: 0.8s;
    animation-fill-mode: both;
  }
  .moveUp {
    -webkit-animation-name: moveUp;
    animation-name: moveUp;
    animation-duration: 0.8s;
    animation-fill-mode: both;
  }
  .moveUp2 {
    -webkit-animation-name: moveUp2;
    animation-name: moveUp2;
    animation-duration: 1.2s;
    animation-fill-mode: both;
  }
  .moveDown {
    -webkit-animation-name: moveDown;
    animation-name: moveDown;
    animation-duration: 0.8s;
    animation-fill-mode: both;
  }
  .moveDown2 {
    -webkit-animation-name: moveDown2;
    animation-name: moveDown2;
    animation-duration: 1.2s;
    animation-fill-mode: both;
  }
  .hidden {
    display: none;
    visibility: hidden;
    opacity: 0 !important;
    transition: opacity 1s linear;
  }
  .visible {
    display: block;
    visibility: visible;
    opacity: 1 !important;
    transition: opacity 1s linear;
  }
`

export const Crosses = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 60px;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    > div {
      padding-bottom: 2rem;
    }
  }
`

export const Cross = styled.div`
  position: relative;

  flex: 1;
  > svg {
    margin-bottom: 30px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .line {
    transition: all 1s linear;
    position: absolute;
    width: 50%;
    top: 70px;
    height: 5px;
    background-color: ${({ theme }) => theme.colors.primary};
    transform: rotate(45deg);
  }
  .right {
    transform: rotate(-45deg);
  }

  > p {
    font-size: 25px;
    color: white;
    max-width: 360px;
  }
  &:nth-child(1) {
    > svg {
      padding-top: 18px;
    }
  }
  &:nth-child(2) {
    > svg {
      padding-top: 30px;
    }
  }
  &:nth-child(3) {
    > svg {
      padding-top: 30px;
    }
  }
`
