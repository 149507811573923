import styled from 'styled-components'

export const SlideWrapper = styled.div`
  min-height: 550px;
  background-color: ${({ theme }) => theme.colors.slideBg};
  padding: 5rem 3rem;
  @media only screen and (max-width: 800px) {
    padding: 0;
    min-height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .slide-3 {
    height: 250px;
    padding-bottom: 30px;
  }
`
export const SlideSubText = styled.p`
  padding: 1.6rem 2rem;
  font-size: 21px;
  color: #505050;
  font-weight: 400;
  line-height: 1.3;
  @media only screen and (max-width: 800px) {
    font-size: 19px;
  }
`

export const SlideInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  > svg {
    max-height: 300px;
    max-width: 300px;
    min-width: 200px;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    > svg {
      margin-bottom: 5%;
      width: 60%;
    }
  }

  @media only screen and (max-width: 465px) {
    svg {
      width: 60%;
    }
  }
`

export const SlideText = styled.div`
  display: block;
  max-width: 650px;
  margin-right: 50px;
  > h3 {
    padding-top: 2rem;
    padding: 0 2rem;
    font-size: ${({ theme }) => theme.sizes.xLarge};
    @media only screen and (max-width: 500px) {
      font-size: 30px;
    }
  }
  @media only screen and (max-width: 800px) {
    margin: 0;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  @media only screen and (max-width: 800px) {
    padding-top: 6px;
    padding-bottom: 16px;
  }
  @media only screen and (max-width: 465px) {
    padding-bottom: 15%;
  }
`
