import styled, { css } from 'styled-components'

/* eslint-disable */

export const HeaderBlockWrapper = styled.div`
  display: flex;
  text-align: ${({ textAlign }) => textAlign || `center`};
  padding-bottom: 4rem;
  padding: ${({ pad }) => pad || `0 0 4rem 0`};
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  min-height: ${({ blockHeight }) =>
    blockHeight ? `${blockHeight}px` : `auto`};
  flex-direction: column;
  justify-content: ${({ position }) => position || `center`};
  align-items: ${({ horizontalAlign }) => horizontalAlign || `center`};
  background-color: ${({ inverse, theme, bgColor }) =>
    bgColor || (inverse ? theme.colors.primary : `white`)};
  color: ${({ inverse }) => (inverse ? `white` : `black`)};
  ${({ secondary, theme }) =>
    secondary &&
    css`
      background-color: ${theme.colors.secondary};
    `}
  position: relative;

  .svg-outer {
    position: absolute;
  }

  .svg-wrapper {
    width: 100vw;
    display: inline-block;
    position: relative;

    vertical-align: middle;
    overflow: hidden;
    height: 50px;
    > svg {
      margin: 0 auto;
      display: inline-block;
      position: absolute;
      left: 0;
    }
  }
  .top {
    top: 0;
    > div > svg {
      top: 0;
    }
  }
  .bot {
    bottom: 0;
    > div > svg {
      bottom: 0;
    }
  }
`

export const HeaderHat = styled.div`
  background-color: ${({ inverse, theme, accent }) =>
    accent || (inverse ? theme.colors.secondary : theme.colors.primary)};
  height: 6px;
  width: 106px;
  margin-bottom: 2rem;
`

export const HeaderBlockText = styled.h2`
  white-space: pre-line;
  font-size: 50px;
  max-width: ${({ headMax }) => headMax || 1300}px;
  @media only screen and (max-width: 1200px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 30px;
  }
  > span {
    padding-left: 0.8rem;
    color: ${({ theme }) => theme.colors.buttonDefault};
    font-size: 4.4rem;
    font-weight: 600;
  }
`

export const SubText = styled.div`
  max-width: ${({ subMax }) => subMax || 950}px;
  padding: 2rem 0 0;
  > p {
    max-width: ${({ subMax }) => subMax || 950}px;
    color: #505050;
    font-size: 21px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 2.2rem;
    @media only screen and (max-width: 700px) {
      font-size: 19px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const HeaderChildren = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  counter-reset: customlistcounter;
  width: 100%;
  > ol {

    font-family: Avenir-Heavy;
    max-width: 1130px;
    margin: 2rem 2rem 0;
  }

  .cta-multi {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 2rem;
    @media only screen and (min-width: 650px){
      .cta {
        &:nth-child(1){
          padding-right: 20px;
        }
        &:nth-child(2){
          padding-left: 20px;
        }
      }
    }
    @media only screen and (max-width: 650px) {
      flex-direction: column;
      .cta {
        &:first-child {
          padding-bottom: 14px;
        }
      }
    }
    }
  }
`
export const HeaderGraphic = styled.div`
  margin-top: 3rem;
  max-width: ${({ maxGraphicWidth }) =>
    !!maxGraphicWidth ? maxGraphicWidth : 1300}px;
  width: 100%;
  display: flex;

  .check-tri {
    justify-content: space-between;
    display: flex;
    width: 100%;
    max-width: 1000px;
    padding-top: 2rem;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > div {
        margin-bottom: 66px;
        &:last-child {
          margin-bottom: 0;
        }
      }

    }

    > div {
      max-width: 224px;
      > svg {
        padding-bottom: 2rem;
      }

      > p {
        font-size: 21px;
        font-weight: 500;
        @media only screen and (max-width: 800px) {
          font-size: 19px;
        }
      }
    }
  }

  justify-content: center;
  .about-trifold-icons {
    box-sizing: border-box;
    margin: 0 auto 2rem;
    display: flex;
    width: 100%;
    max-width: 800px;
    justify-content: space-between;
    @media only screen and (max-width: 600px) {
      margin: 0;
    }
    > svg {
      &:nth-child(2) {
        margin: 0 20px;
      }
    }
  }

  .about-text-trifold {
    padding-top: 30px;
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    @media only screen and (max-width: 764px) {
      flex-direction: column;
      > div {
        padding-bottom: 120px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    .top-text {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .middle-text {
      font-size: 90px;
      color: #ff4f67;
      margin-bottom: 30px;
    }
    .bot-text {
      font-size: 48px;
    }
  }



  .people-graphic {
      max-width: 1200px;
      justify-content: center;
      flex-wrap: wrap
      display: flex;
      justify-items: center;
      > svg {
        margin-right: 12px;
        margin-left: 12px;
      }
      @media only screen and (max-width: 1200px) {
        svg {
         width: 70px;
       }
     }
     @media only screen and (max-width: 1002px) {
       max-width: 700px;
       svg {
         width: 66px;
       }
     }
     @media only screen and (max-width: 700px) {
       max-width: 600px;
       svg {
         margin: 0 8px !important;
         width: 56px;
       }
     }
     @media only screen and (max-width: 424px) {
       max-width: 350px;
       svg {
         margin: 0 8px !important;
         width: 56px;
       }
     }
    }
  }
`

export const SpanText = styled.div`
  position: relative;
  max-width: 290px;
  max-height: 90px;
  margin-top: 20px;
  @media only screen and (max-width: ${({ breakP }) =>
      !!breakP ? breakP : '700px'}) {
    margin: 20px auto 0;
    max-width: 250px;
    max-height: 80px;
  }
  > h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .path {
    stroke-dasharray: 800;
    animation: dash 1.3s ease-in 0.3s;
  }
  @keyframes dash {
    from {
      stroke-dashoffset: 800;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`
