import React from 'react'
import { graphql } from 'gatsby'

// Shared UI
import {
  HeaderBlock,
  SplitScreen,
  OverlapBlock,
  CtaButton,
  Showcase,
} from 'shared-ui'

// Features
import Layout from '../../features/Layout'
import SEO from '../../features/Seo'

// Primitives
import DownloadButtons from '../../primitives/DownloadButtons'

// Svgs

import TopLeft from '../../assets/mentors-top-left.svg'
import BotRight from '../../assets/mentors-bottom-right.svg'
import Mid from '../../assets/mentors-middle.svg'

export const query = graphql`
  query MentorsPageQuery {
    hero: file(relativePath: { eq: "mentorsHero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 407, quality: 100, layout: CONSTRAINED)
      }
    }
    g1: file(relativePath: { eq: "mentorsG1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 625, quality: 100, layout: CONSTRAINED)
      }
    }
    g2: file(relativePath: { eq: "mentorsG2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 610, quality: 100, layout: CONSTRAINED)
      }
    }
    g3: file(relativePath: { eq: "mentorsG3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 505, quality: 100, layout: CONSTRAINED)
      }
    }
    g4: file(relativePath: { eq: "mentorsG4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 603, quality: 100, layout: CONSTRAINED)
      }
    }
    g5: file(relativePath: { eq: "mentorsG5.png" }) {
      childImageSharp {
        gatsbyImageData(width: 517, quality: 100, layout: CONSTRAINED)
      }
    }
    g6: file(relativePath: { eq: "mentorsG6.png" }) {
      childImageSharp {
        gatsbyImageData(width: 598, quality: 100, layout: CONSTRAINED)
      }
    }
    g7: file(relativePath: { eq: "mentorsG7.png" }) {
      childImageSharp {
        gatsbyImageData(width: 609, quality: 100, layout: CONSTRAINED)
      }
    }
    showcase1: file(relativePath: { eq: "showcase1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
      }
    }
    showcase2: file(relativePath: { eq: "showcase2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
      }
    }
    showcase3: file(relativePath: { eq: "showcase3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

const MentorsPage = ({ data }) => {
  const mentorsPageData = {
    hero: {
      pad: '5rem 2rem 0',
      max: '417px',
      bgColor: '#f9faff',
      innerMax: '1240px',
      headMax: '550px',
      mobileBar: {
        text: 'Help the Next Generation  |  Click Here',
        to: '/mentors/downloads/',
      },
      heroText:
        "Enhance a student's access to guidance, advice, and perspectives",
      subText: (
        <p>
          The entire process around how a student connects to professionals
          needs to be rethought in the COVID era.
        </p>
      ),
      graphic: data.hero.childImageSharp.gatsbyImageData,
      vectors: [
        {
          vector: <Mid />,
          styles: { bottom: '20%', position: 'absolute' },
        },
        {
          vector: <TopLeft />,
          styles: { top: '0', left: '0', position: 'absolute' },
        },
        {
          vector: <BotRight />,
          styles: { bottom: '0', right: '0', position: 'absolute' },
        },
      ],
      slug: '/mentors/downloads/',
    },
    showcase1: {
      cards: [
        {
          image: data.showcase1.childImageSharp.gatsbyImageData,
          name: `Kelsey Cavanaugh`,
          role: `Healthcare Manager at Protiviti`,
          headPhrase: 'Looking to help with:',
          options: [
            `Career Planning`,
            `Effective Networking`,
            `Interview Strategies`,
          ],
        },
        {
          image: data.showcase2.childImageSharp.gatsbyImageData,
          name: `Tracy Preston`,
          role: `EVP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [`Building Personal Brand`, `Career Advise`, `Networking`],
        },
        {
          image: data.showcase3.childImageSharp.gatsbyImageData,
          name: `Stefanie Tsen`,
          role: `SVP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [
            `Understanding marketing`,
            `Inspiring others`,
            `Networking for your future`,
          ],
        },
      ],
    },
    footer: {
      download: false,
      head: `Sign up today!`,
      sub: `Be part of the positive social change we need and help a student who is taking their first step into the real world.`,
      buttons: (
        <CtaButton inverse slug="/mentors/pledge/" bgColor="white">
          Take the Pledge
        </CtaButton>
      ),
      subMax: 800,
    },
  }

  return (
    <Layout pageData={mentorsPageData}>
      <SEO title="Mentors" />

      <OverlapBlock
        full
        rightPad="59px"
        textMax="1250px"
        head="Preventing a Lost Generation"
        sub={
          <>
            <p>
              The coronavirus will devastate on campus recruiting this year.
              These events and the subsequent opportunities that come from these
              interactions are critical to ensuring students have access to the
              networks they need to secure their first jobs. Without these
              networks, the risk of becoming underemployed is high, especially
              for those who come from underserved backgrounds.
            </p>
            <p>
              As a mentor, you can ensure this does not happen. By connecting
              professionals with students, we can rebuild these networks and
              ensure we do not have a lost generation. And given these
              unprecedented times, it is one of the few solutions that can be
              done at scale and on a virtual basis, ensuring the help can get to
              where it is needed most.
            </p>
          </>
        }
        buttons={
          <>
            <CtaButton slug="/mentors/pledge/">Take the Pledge</CtaButton>
            <CtaButton secondary outLink="/advocate-materials.pdf">
              Advocate Materials
            </CtaButton>
          </>
        }
      />

      <HeaderBlock
        headerText="Are you ready to help the next generation thrive?"
        pad="10rem 2rem 8rem"
      />

      <SplitScreen
        first
        head="Help the next generation and gain new perspectives"
        sub="Be part of the positive social change this country needs. Through the 100k Mentor Challenge you are gauranteed to have an impact on the next generation. And mentorship is not a one way street either. Gain new perspectives that you can bring to your own job and learn to how to effectivelty communicate with Gen Z."
        graphic={data.g1.childImageSharp.gatsbyImageData}
        gWidth={605}
        gHeight={507}
        textMax="490px"
        innerMax="1100px"
        pad="0 23px 8rem"
      />
      <SplitScreen
        reverse
        head={'Work with smart, \n ambitious talent'}
        sub="All mentees are screened to ensure they are motivated and want to learn. They also take a pledge to be prepared for each session to ensure they’re here because they want to succeed and improve under your guidance."
        graphic={data.g2.childImageSharp.gatsbyImageData}
        gWidth={450}
        gHeight={700}
        innerMax="1050px"
      />
      <SplitScreen
        head={'A schedule that fits \n your life'}
        sub="We know life is hectic. Engage at your pace with a program that flexibly adjusts to and accounts for your schedule. We have also built a series of guides to ensure you do not have to spend a lot of time prepping or worrying about what to talk about. To have an impact only requires that you connect with your mentee for one hour a month."
        graphic={data.g3.childImageSharp.gatsbyImageData}
        gWidth={505}
        textMax={'480px'}
      />
      <SplitScreen
        reverse
        pad="8rem 2rem 14rem"
        head={'Hit the ground running with our suite of features and tools'}
        sub="We’ve designed our platform so you can have an immediate impact. From in-app chat and scheduling to comprehensive mentor/mentee guides and more, we’re constantly updating the features to ensure you can engage in new and meaningful ways with your mentees."
        graphic={data.g4.childImageSharp.gatsbyImageData}
        textMax="500px"
        innerMax="1100px"
        gWidth={503}
        gHeight={558}
      />

      <HeaderBlock
        pad="2rem 0 4rem"
        headerText="With just one hour a month, a mentor can change a student’s life"
        headMax={1050}
      />

      <Showcase content={mentorsPageData.showcase1.cards} />
      <div
        style={{
          width: `100%`,
          display: `flex`,
          justifyContent: `center`,
          marginTop: '-6rem',
        }}
      >
        <CtaButton slug="/mentors/pledge/">Take the Pledge</CtaButton>
      </div>

      <HeaderBlock
        position="flex-end"
        pad="10rem 0 8rem"
        headerText="Quick. Easy. Mobile."
        subText={
          <p>
            Companies are rescinding internships and full time offers. As a
            mentor, you can help students rebuild their networks and guide them
            to their first job.
          </p>
        }
        subMax={800}
      />

      <SplitScreen
        first
        head="Registering is simple"
        sub="Just download the 100k Mentor App and complete your profile to help us match you with your ideal mentee."
        graphic={data.g5.childImageSharp.gatsbyImageData}
        gWidth={430}
        gHeight={601}
        pad="0 2rem 8rem"
      />
      <SplitScreen
        reverse
        head={'Decide where you \n can help'}
        sub="Based on your experience and preferences you can decide the areas in which you help and support students."
        graphic={data.g6.childImageSharp.gatsbyImageData}
        gWidth={430}
        gHeight={542}
      />
      <SplitScreen
        head={'Match with prepared mentees'}
        sub="After registering, we match you to a student whose professional aspirations align with your expertise. To ensure the highest chances of success, mentees take a pledge to come prepared to meetings and are provided a quick start guide so you can get started right away."
        graphic={data.g7.childImageSharp.gatsbyImageData}
        gWidth={430}
        gHeight={652}
        pad="8rem"
      />
      <HeaderBlock pad="4rem 0 12rem" headerText="Download the app today">
        <div>
          <div style={{ padding: '1rem' }} />
          <DownloadButtons />
        </div>
      </HeaderBlock>
    </Layout>
  )
}

export default MentorsPage
