import React from 'react'
import { ContactForm } from 'shared-ui'

import Layout from '../features/Layout'

const ContactPage = () => {
  const contactPageData = {
    noSupporters: true,
    footer: {
      head: `Want to connect?`,
      sub: `Use the contact form to let us know!`,
    },
  }
  return (
    <Layout pageData={contactPageData}>
      <ContactForm siteKey="100k Request" />
    </Layout>
  )
}

export default ContactPage
