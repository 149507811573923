import styled from 'styled-components'

export const TextBlock = styled.div`
  max-width: ${({ textMax }) => textMax || `600px`};
  padding: 4rem 2rem 3rem;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > h3 {
    font-size: 50px;
    margin-bottom: 2rem;
    @media only screen and (max-width: 1200px) {
      font-size: 40px;
    }
    @media only screen and (max-width: 700px) {
      font-size: 30px;
    }
  }
  .paras {
    font-size: 21px;
    > p {
      margin-bottom: 2rem;
    }
  }

  @media only screen and (max-width: ${({ breakpoint }) => breakpoint}px) {
    width: 100%;
    max-width: 43%;
    .paras {
      max-width: 100%;
    }
  }

  @media only screen and (max-width: ${({ mobileBreak }) => mobileBreak}px) {
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    h3 {
      text-align: center;
    }
  }
  @
`

export const Wrapper = styled.section``
