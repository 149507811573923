import React from 'react'
import { graphql } from 'gatsby'
import { withArtDirection, getImage } from 'gatsby-plugin-image'
import { HowItWorks, CtaButton } from 'shared-ui'
import Layout from '../../features/Layout'
import Seo from '../../features/Seo'
import { FirstGen, Pocs, LGBT, Vet, Ada } from '../../primitives/Icons'

export const query = graphql`
  query UniHIWPageQuery {
    split1Main: file(relativePath: { eq: "split1UniMain.png" }) {
      childImageSharp {
        gatsbyImageData(width: 448, quality: 100, layout: CONSTRAINED)
      }
    }
    split1Head: file(relativePath: { eq: "split1UniHead.png" }) {
      childImageSharp {
        gatsbyImageData(width: 88, quality: 100, layout: CONSTRAINED)
      }
    }
    split2p: file(relativePath: { eq: "split2Uni.png" }) {
      childImageSharp {
        gatsbyImageData(width: 88, quality: 100, layout: FIXED)
      }
    }
    imageSectionLeft: file(relativePath: { eq: "imageSectionDeanna.png" }) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    imageSectionCenter: file(relativePath: { eq: "imageSectionEric.png" }) {
      childImageSharp {
        gatsbyImageData(width: 428, quality: 100, layout: CONSTRAINED)
      }
    }
    imageSectionRight: file(relativePath: { eq: "imageSectionStef.png" }) {
      childImageSharp {
        gatsbyImageData(width: 332, quality: 100, layout: CONSTRAINED)
      }
    }
    split3a: file(relativePath: { eq: "captionImageTracy.png" }) {
      childImageSharp {
        gatsbyImageData(width: 374, quality: 100, layout: CONSTRAINED)
      }
    }
    arrowGMain: file(relativePath: { eq: "mentorArrowGMain.png" }) {
      childImageSharp {
        gatsbyImageData(width: 371, quality: 100, layout: CONSTRAINED)
      }
    }
    arrowRow: file(relativePath: { eq: "mentorArrowG.png" }) {
      childImageSharp {
        gatsbyImageData(width: 752, quality: 100, layout: CONSTRAINED)
      }
    }
    larry: file(relativePath: { eq: "statRowsLarry.png" }) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    kelsey: file(relativePath: { eq: "statRowsKelsey.png" }) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    split4Main: file(relativePath: { eq: "fakeSlider2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
      }
    }
    stevieQ: file(relativePath: { eq: "stevieQ.png" }) {
      childImageSharp {
        gatsbyImageData(width: 60, quality: 100, layout: FIXED)
      }
    }
    last: file(relativePath: { eq: "HIWFooterB.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    last_tablet: file(relativePath: { eq: "HIWFooterB_tablet.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    last_mobile: file(relativePath: { eq: "HIWFooterB_mobile.png" }) {
      childImageSharp {
        gatsbyImageData(width: 356, quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`

const HowItWorksPage = ({ data }) => {
  const pageData = {
    noSupporters: true,
    howItWorks: {
      split1: {
        graphicHeadComponent: {
          img: data.split1Head.childImageSharp.gatsbyImageData,
          text:
            'How universities partner with ProMazo & the 100k Mentor Challenge',
        },
        graphic: data.split1Main.childImageSharp.gatsbyImageData,
        quoteText:
          'We want to help our students build connections with industry professionals',
        quotePad: '4rem 0',
        maxWidth: '650px',
        arrowLink: '/universities/',
        ctaLink: '/contact/',
      },
      separator: {
        text1: "Here's how we build a",
        bold: 'unique mentorship program',
        text2: 'for your students',
      },
      imageSection: {
        headingWidth: '620px',
        heading: 'Students are connected to industry professionals',
        left: {
          fluid: data.imageSectionLeft.childImageSharp.gatsbyImageData,
          caption: 'Deanna Jones, Baker Hughes',
        },
        center: {
          fluid: data.imageSectionCenter.childImageSharp.gatsbyImageData,
          caption: 'Eric Severson, Neiman Marcus Group',
        },
        right: {
          fluid: data.imageSectionRight.childImageSharp.gatsbyImageData,
          caption: 'Stefanie Tsen, Neiman Marcus Group',
        },
      },
      split2: {
        headerText: 'It all begins with a roll out plan',
        subText:
          'We sit down with university leaders ranging from deans to career centers and D&I officials to understand the best way to partner with your university.',
        accordions: [
          {
            title: 'Where do the mentors come from?',
            content:
              'Mentors are employees who come from a wide variety of companies ranging from unicorn startups to Fortune 500 companies with a diverse array of professional experieneces.',
          },
          {
            title: 'What types of students are eligble for the program?',
            content:
              'We focus on supporting students from diverse backgrounds (people of color, women, first generation college students, students with disabilities, and veterans).',
          },
          {
            title: 'How much does it cost?',
            content:
              '$0. We Want to help as many students as possible and this is why it is completely free for universities to take part in the initiative.',
          },
        ],
        carouselContent: {
          content: [
            {
              title: 'I. Initial Roll Out',
              profile: {
                fixed: data.split2p.childImageSharp.gatsbyImageData,
              },
              targetsSection: {
                heading: 'Student Goals',
                paras: [
                  <p>
                    Mentor Goals: <br />
                    <span>25 - 75 during Fall Semester</span>
                  </p>,
                ],
              },
              recruitingOpps: {
                heading: 'Education',
                paras: [
                  <p>
                    Open Recruiting Fields: <br />
                    <span>Finance, IT, Accounting</span>
                  </p>,
                ],
              },
              desiredSkills: {
                header: 'Student Engagement Focus',
                content: [
                  {
                    skill: '1st Generation',
                    icon: <FirstGen />,
                  },
                  {
                    skill: 'POCs',
                    icon: <Pocs />,
                  },
                ],
              },
            },
            {
              title: 'II. Partnering with ERGs',
              profile: {
                fixed: data.split2p.childImageSharp.gatsbyImageData,
              },
              targetsSection: {
                heading: 'Student Goals',
                paras: [
                  <p>
                    Student Sign Ups: <br />
                    <span>500-1,000 students</span>
                  </p>,
                ],
              },
              recruitingOpps: {
                heading: 'Education',
                paras: [
                  <p>
                    Open Recruiting Fields: <br />
                    <span>Finance, IT, Accounting, Marketing</span>
                  </p>,
                ],
              },
              desiredSkills: {
                header: 'Student Engagement Focus',
                content: [
                  {
                    skill: '1st Generation',
                    icon: <FirstGen />,
                  },
                  {
                    skill: 'LGBTQ+',
                    icon: <LGBT />,
                  },
                  {
                    skill: 'POCs',
                    icon: <Pocs />,
                  },
                ],
              },
            },
            {
              title: 'III. Full Roll Out',
              profile: {
                fixed: data.split2p.childImageSharp.gatsbyImageData,
              },
              targetsSection: {
                heading: 'Student Goals',
                paras: [
                  <p>
                    Mentor Goals: <br />
                    <span>1,000 - 3,000 students</span>
                  </p>,
                ],
              },
              recruitingOpps: {
                heading: 'Education',
                paras: [
                  <p>
                    Open Recruiting Fields: <br />
                    <span>Finance, IT, Accounting, Marketing, HR</span>
                  </p>,
                ],
              },
              desiredSkills: {
                header: 'Student Engagement Focus',
                singleRow: true,
                content: [
                  {
                    skill: '1st Gen',
                    icon: <FirstGen />,
                  },
                  {
                    skill: 'LGBTQ+',
                    icon: <LGBT />,
                  },
                  {
                    skill: 'POCs',
                    icon: <Pocs />,
                  },
                  {
                    skill: 'Veteran',
                    icon: <Vet />,
                  },
                  {
                    skill: 'ADA',
                    icon: <Ada />,
                  },
                ],
              },
            },
          ],
        },
      },
      split3: {
        accordions: [
          {
            title: 'What is the length of a mentorship?',
            content:
              'The match is for four months. We call the period where a mentee is connected to a mentor a Season.',
          },
          {
            title: "What if a student doesn't like their selection?",
            content:
              'We encourage them to try to work with their mentor, but sometimes the connection is not there. They can contact us and we will get them set up with another mentor (limits apply).',
          },
          {
            title: 'What are the commitments?',
            content:
              'From the university perspective, we just ask that you work with us to help spread the word on campus. Students are required to come prepared to each session and meet with their mentor once a month during a Season.',
          },
        ],
        graphic: {
          fluid: data.split3a.childImageSharp.gatsbyImageData,
          caption: 'Tracy Preston, Neiman Marcus Group ',
        },
      },
      statRowsImages: [
        {
          fluid: data.larry.childImageSharp.gatsbyImageData,
          caption: 'Larry Deutsch, DVK',
        },
        {
          fluid: data.kelsey.childImageSharp.gatsbyImageData,
          caption: 'Kelsey Cavanaugh, Protiviti',
        },
      ],
      statRows: [
        {
          stat: '10k+',
          para: {
            head: 'Professionals',
            text:
              'Thousands of professionals are waiting to help your students successfully take the next step.',
          },
        },
        {
          stat: '4-6',
          para: {
            head: 'Potential Matches',
            text:
              'We have partnered with companies from a diverse set of industries to ensure we can help students from a variety of majors.',
          },
        },
        {
          stat: '1',
          para: {
            head: 'Mentor',
            text:
              'We find the perfect match for each student. And they can have multiple matches over their college careers.',
          },
        },
      ],
      arrowGraphic: {
        headMax: '608px',
        head: 'Mentors meet with their mentees throughout a four month Season',
        leftP: {
          head: 'Learn from top industry professionals',
          para:
            'Gain the insight and advice your students need to help them determine where they want to go and secure their first job.',
        },
        rightP: {
          head: 'Paths to guide their discussions',
          para:
            'We built a series of guides to support their discussions, laying the foundation for the relationship to thrive.',
          rowGraphic: data.arrowRow.childImageSharp.gatsbyImageData,
        },
        mainG: data.arrowGMain.childImageSharp.gatsbyImageData,
      },
      split4: {
        headerText: "Reimagine how students network in today's virtual age",
        headMax: '740px',
        subHead: 'See & Feel the Impact',
        quoteText:
          'Working with my mentor completely changed my decision on what I wanted to do. Without their support, I could not have made the change, but looking back on it, I am so happy that I did.',
        profile: {
          img: data.stevieQ.childImageSharp.gatsbyImageData,
          name: 'Stevie Q.',
        },
        graphic: data.split4Main.childImageSharp.gatsbyImageData,
        accordions: [
          {
            title: 'What happens at the end of a Season?',
            content:
              'At the end of a Season, mentors and mentees have the option of continuing with each other or requesting another match. Or if their schedule is too hectic, they can take a Season off.',
          },
          {
            title: 'How does the partnership expand?',
            content:
              'With our corporate partnerships spanning dozens of industries, we have the ability to support your entire student body. Please contact us for more information about enrolling a large number of students into the program at once.',
          },
          {
            title:
              'Can students remain connected with their mentors after the program?',
            content:
              'Yes! We highly encourage it and hope this program becomes the foundation for many years of them working together.',
          },
        ],
      },
      last: {
        headerText:
          'Along the way, your students will build their personal board of directors...',
        maxWidth: '700px',
        fluid: withArtDirection(getImage(data.last), [
          {
            media: `(max-width: 500px)`,
            image: getImage(data.last_mobile),
            height: data.last_mobile.childImageSharp.gatsbyImageData.height,
          },
          {
            media: `(max-width: 1200px)`,
            image: getImage(data.last_tablet),
            height: data.last_tablet.childImageSharp.gatsbyImageData.height,
          },
        ]),
      },
    },
    footer: {
      head: `Are you ready to get your students connected?`,
      headMax: '1200',
      headBgColor: '#441779',
      buttons: (
        <CtaButton slug="/contact/" bgColor="white" textColor="#441779">
          Let's Chat
        </CtaButton>
      ),
    },
  }
  return (
    <Layout pageData={pageData}>
      <Seo title="How it Works - Universities" />
      <HowItWorks {...pageData.howItWorks} />
    </Layout>
  )
}

export default HowItWorksPage
