import React from 'react'
import {
  SlideWrapper,
  SlideSubText,
  SlideInner,
  SlideText,
  ButtonWrapper,
} from './InfoSlide.styles'

import { CtaButton } from 'shared-ui'

const InfoSlide = ({ head, sub, graphic }) => (
  <SlideWrapper>
    <SlideInner>
      <SlideText>
        <h3>{head}</h3>
        <SlideSubText>{sub}</SlideSubText>
      </SlideText>

      {graphic}
    </SlideInner>
    <ButtonWrapper>
      <CtaButton slug="/companies/pledge/">Take the pledge today</CtaButton>
    </ButtonWrapper>
  </SlideWrapper>
)

export default InfoSlide
