export default {
  fonts: {
    bl: `Avenir-Black`,
    hv: `Avenir-Heavy`,
    md: `Avenir-Medium`,
    lt: `Avenir-Light`,
  },
  colors: {
    active1: '#f84f68',
    logo: '#f84949',
    secondary: '#38175b',
    secondaryLight1: '#9672c0',
    primary: '#ff4f67',
    primary2: '#fe4f67',
    main1: '#ff455e',
    dark1: '#000000',
    grey1: '#505050',
    grey2: '#979797',
    light1: '#ffffff',
    gradient1: `linear-gradient(270deg, rgba(255, 135, 160, 0) 0%, rgba(254, 105, 130, 0.69) 15%, rgba(252, 80, 103, 0.91) 100%)`,
    // Component Specific
    sTrifold1: '#38175b',
    sTrifold2: '#3c1665',
    sTrifold3: '#441779',
    tricolor1: 'rgb(255, 79, 103)',
    tricolor2: 'rgb(150, 114, 192)',
    tricolor3: 'rgb(68, 23, 121)',
    overlap: '#38175b',
    buttonDefault: '#ff4f67',
    headerAccent: '#ff4f67',
    slideBg: '#f3eff7',
    formCircle: 'rgb(68, 23, 121)',
    formLine1: 'rgb(150, 114, 192)',
    formLine2: 'rgb(150, 114, 192)',
    label: 'rgb(150,114,192)',
  },
  breakpoints: {
    nav: 1310,
    mobile: 'only screen and (max-width: 680px)',
    tabletSm: 'only screen and (max-width: 1000px)',
    tablet: 'only screen and (max-width: 1310px)',
    desktop: 'only screen and (min-width: 1310px)',
  },
  sizes: {
    xxSmall: '.25rem',
    xSmall: '.5rem',
    small: '1rem',
    smMd: '1.25rem',
    medium: '1.875rem',
    large: '3rem',
    xLarge: '2.75rem',
    xxLarge: '3.75rem',
  },
  animations: {
    button: 'box-shadow 0.3s ease',
    link: 'color 0.2s ease',
  },
  shadows: {
    shadow1: '0px 5px 20px rgba(30, 30, 31, 0.05)',
  },
}
