import React from 'react'
import PropTypes from 'prop-types'

import { HeaderHat } from '../../primitives/HeaderBlock/HeaderBlock.styles'
import { FaqWrapper, Questions, Heading, Paragraphs, Title } from './Faq.styles'

const defaultQuestions = [
  {
    question: 'What is the 100k Mentor Challenge?',
    answer: [
      'The 100k Mentor Challenge is a national movement and coalition of students from across the country partnering with ProMazo to provide 100,000 Mentors to 100,000 students from underrepresented backgrounds during the upcoming academic year.',
      'The entire recruiting process has been upended because of the coronavirus and it has decimated the chance for students, especially those from underrepresented backgrounds, to build the networks that are crucial to securing full time work after college. We wanted to provide those without a network to fall back on with the support that will be necessary to survive this crisis and ensure we don’t have a lost generation just when diversity efforts within higher education have begun to pay off. Our goal is to create immersive mentorship experiences so students can receive the guidance they need before graduation, helping them develop lifelong advocates for their professional careers. We also hope it will be a new way for companies to sponsor people from underrepresented backgrounds to join their firm and recruit the next generation of leaders.',
    ],
  },
  {
    question: 'Who are the people behind the 100k Mentor Challenge?',
    answer: [
      'The leaders of the movement are students who are directly impacted by the changes that are happening in recruiting. ProMazo, through its student networks, was able to help assemble a wide variety of students to lead different parts of the movement. The 100K Mentors Challenge has over 40 students working on the initiative to ensure we reach our goals. Currently, there are four different teams working together to turn our idea into a reality. The App Team is building out the technical platform on iOS and Android, the Algorithm Team focuses on correctly matching students to mentors, the University Team is focused on developing and executing the strategy to register students as mentees on the app and the Corporate Engagement team’s goal is to build a coalition of corporate supporters and register mentors. ProMazo has donated part of the technology to run the mentorship program and because of its work with companies and students in the past is guiding the program as their way of giving back during this crisis.',
    ],
  },
]

const Faq = ({ questions, title }) => (
  <FaqWrapper>
    <Heading>
      <HeaderHat />
      <h2>Frequently Asked Questions</h2>
      {!!title && <Title>{title}</Title>}
    </Heading>
    <Questions>
      {questions.map((q, i) => (
        <li key={`faq-${i}`}>
          <h4>{q.question}</h4>
          {!q.list ? (
            <Paragraphs>
              {q.answer.map((para, k) => (
                <p key={`answer-${i}-para-${k}`}>{para}</p>
              ))}
            </Paragraphs>
          ) : (
            <>
              {q.answerSubHead && (
                <Paragraphs>
                  <p>{q.answerSubHead}</p>
                </Paragraphs>
              )}
              <ul className="nested-list">
                {q.answer.map((li, j) => (
                  <li key={`nested-list-${i}-item-${j}`}>{li}</li>
                ))}
              </ul>
            </>
          )}
        </li>
      ))}
    </Questions>
  </FaqWrapper>
)

Faq.defaultProps = {
  questions: defaultQuestions,
}

Faq.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
}

export default Faq
