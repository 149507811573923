import React from 'react'
import { graphql } from 'gatsby'

// Shared UI components
import {
  CtaButton,
  SlideTrifold,
  HeaderBlock,
  SplitScreen,
  OverlapBlock,
  Showcase,
} from 'shared-ui'

// Features
import Layout from '../../features/Layout'
import InfoSlider from '../../features/InfoSlider'
import SEO from '../../features/Seo'
import Founders from '../../features/Founders'

// Icons
import { Idea, People, PeopleArrow } from '../../primitives/Icons'

import Left from '../../assets/companies-left.svg'
import Right from '../../assets/companies-right.svg'
import Mid from '../../assets/companies-middle.svg'

export const query = graphql`query CompaniesPageQuery {
  hero: file(relativePath: {eq: "companiesHero.png"}) {
    childImageSharp {
      gatsbyImageData(width: 555, quality: 100, layout: CONSTRAINED)
    }
  }
  g0: file(relativePath: {eq: "companiesG0.png"}) {
    childImageSharp {
      gatsbyImageData(width: 653, quality: 100, layout: CONSTRAINED)
    }
  }
  g1: file(relativePath: {eq: "companiesG1.png"}) {
    childImageSharp {
      gatsbyImageData(width: 447, quality: 100, layout: CONSTRAINED)
    }
  }
  g2: file(relativePath: {eq: "companiesG2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 539, quality: 100, layout: CONSTRAINED)
    }
  }
  g3: file(relativePath: {eq: "companiesG3.png"}) {
    childImageSharp {
      gatsbyImageData(width: 627, quality: 100, layout: CONSTRAINED)
    }
  }
  g4: file(relativePath: {eq: "companiesG4.png"}) {
    childImageSharp {
      gatsbyImageData(width: 565, quality: 100, layout: CONSTRAINED)
    }
  }
  g5: file(relativePath: {eq: "companiesG5.png"}) {
    childImageSharp {
      gatsbyImageData(width: 507, quality: 100, layout: CONSTRAINED)
    }
  }
  showcase1: file(relativePath: {eq: "showcase1.png"}) {
    childImageSharp {
      gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
    }
  }
  showcase2: file(relativePath: {eq: "showcase2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
    }
  }
  showcase3: file(relativePath: {eq: "showcase3.png"}) {
    childImageSharp {
      gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
    }
  }
  supporters1: file(relativePath: {eq: "supporters1.png"}) {
    childImageSharp {
      gatsbyImageData(width: 220, quality: 100, layout: FIXED)
    }
  }
  supporters2: file(relativePath: {eq: "supporters2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 220, quality: 100, layout: FIXED)
    }
  }
  supporters3: file(relativePath: {eq: "supporters3.png"}) {
    childImageSharp {
      gatsbyImageData(width: 210, layout: FIXED)
    }
  }
  supporters4: file(relativePath: {eq: "supporters4.png"}) {
    childImageSharp {
      gatsbyImageData(width: 220, layout: FIXED)
    }
  }
  supporters5: file(relativePath: {eq: "supporters5.png"}) {
    childImageSharp {
      gatsbyImageData(width: 190, layout: FIXED)
    }
  }
}
`

const CompaniesPage = ({ data }) => {
  const companiesPageData = {
    hero: {
      pad: '4.8rem 2rem',
      max: '520px',
      bgColor: '#f9faff',
      breakP: '1070px',
      mobileBar: {
        text: 'Recruit the Next Generation  |  Click Here',
        to: '/contact/',
      },
      innerMax: '1250px',
      headMax: '500px',
      heroText: 'Building professional connections in a work from home world',
      slug: '/contact/',
      subText: (
        <p>
          Showcase your values and impact to your customers, employees and the
          community in a social, scalable, and digital way.
        </p>
      ),
      graphic: data.hero.childImageSharp.gatsbyImageData,
      vectors: [
        {
          vector: <Mid />,
          styles: { bottom: '0', right: '0', position: 'absolute' },
        },
        {
          vector: <Left />,
          styles: { bottom: '0', left: '0', position: 'absolute' },
        },
        {
          vector: <Right />,
          styles: { bottom: '0', right: '0', position: 'absolute' },
        },
      ],
    },

    footer: {
      head: `Ready to take the pledge and get started?`,
      buttons: (
        <CtaButton bgColor="white" slug="/companies/pledge/" inverse>
          Take the Pledge Today
        </CtaButton>
      ),
    },
    trifold1: {
      cards: [
        {
          icon: <PeopleArrow />,
          head: `Support Your Staff`,
          sub: `Help them be a force for positive
          social change in their community`,
        },
        {
          icon: <People />,
          head: `Support Your Community`,
          sub: `Show your customers and other constituents how you live your values`,
        },
        {
          icon: <Idea />,
          head: `Support The Next Generation`,
          sub: `Thousands of students need the help and guidance your employees can provide`,
        },
      ],
    },
    showcase1: {
      cards: [
        {
          image: data.showcase1.childImageSharp.gatsbyImageData,
          name: `Kelsey Cavanaugh`,
          role: `Healthcare Manager at Protiviti`,
          headPhrase: 'Looking to help with:',
          options: [
            `Career Planning`,
            `Effective Networking`,
            `Interview Strategies`,
          ],
        },
        {
          image: data.showcase2.childImageSharp.gatsbyImageData,
          name: `Tracy Preston`,
          role: `Executive VP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [`Building Personal Brand`, `Career Advice`, `Networking`],
        },
        {
          image: data.showcase3.childImageSharp.gatsbyImageData,
          name: `Stefanie Tsen`,
          role: `SVP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [
            `Understanding Marketing`,
            `Inspiring Others`,
            `Networking for your Future`,
          ],
        },
      ],
    },
  }

  return (
    <Layout pageData={companiesPageData}>
      <SEO title="Companies" />

      <OverlapBlock
        blockW="1200px"
        textMax="800px"
        head="Talent is everywhere, but opportunities for those from underserved communities are not."
        sub="The 100k Mentor Challenge is a unique program that allows companies to build bridges to students who need guidance and come from underrepresented backgrounds. Through the program your company becomes the solution, opening opportunities to incredibly talented people no matter where they come from. It also completely transforms and digitizes how a company builds relationships with their future workforce in this Coronavirus era. All this is done at scale, in a flexible and turnkey way."
        buttons={
          <CtaButton slug="/companies/pledge/">Take the pledge today</CtaButton>
        }
        graphic={data.g1.childImageSharp.gatsbyImageData}
        maxWidth={400}
        maxHeight={634}
        imgMargin="50px 0 60px -150px"
        bgPct="76%"
      />
      <HeaderBlock headerText="How does it work?" pad="10rem 0 3rem 0" />

      <InfoSlider />

      <HeaderBlock
        pad="10rem 0 4rem 0"
        headMax={1200}
        headerText="Help the next generation while building connections to your future workforce"
      />

      <div
        style={{
          backgroundImage: `url(/wavy-bg.svg)`,
          backgroundRepeat: `no-repeat`,
          overflow: `visible`,
          padding: '8rem 23px',
          backgroundPositionY: `0rem`,
          backgroundSize: `cover`,
          backgroundOrigin: `border-box`,
          zIndex: `500`,
        }}
      >
        <SplitScreen
          head={'End geographic barriers \n to your recruiting efforts'}
          sub="In the Coronavirus era, building relationships with recruits and supporting their professional aspirations is no longer limited to a particular geography. The 100k Mentor app breaks geographical boundaries and through its algorithms creates an automated and self-managing system that can scale and allow employees to connect with the next generation of your workforce in a novel way. And that’s just the start, scroll down to see what else we cooked up to modernize mentorship for Gen Z..."
          graphic={data.g0.childImageSharp.gatsbyImageData}
          gWidth={653}
          gHeight={415}
          textColor="black"
          innerMax="1190px"
          textMax="540px"
          innerMiddle="6%"
        />
      </div>

      <HeaderBlock
        pad="9rem 0 8rem 0"
        headerText="Empower your workforce to take action while helping the next generation"
        headMax={1110}
      />

      <SplitScreen
        head="Inspire & Attract"
        sub="Through interactions with their mentors, mentees will be able to learn about your company’s culture, values and how your organization creates an environment for young people to succeed."
        graphic={data.g2.childImageSharp.gatsbyImageData}
        gHeight={659}
        gWidth={539}
        first
        pad="0 23px 8rem"
        textMax="480px"
      />

      <SplitScreen
        reverse
        head="Develop & Support"
        sub="Transforming independent contributors into managers can be difficult. Mentoring the next generation enhances an employee’s leadership and communication skills, allowing them to bring these learnings back to their job and become a high performing manager."
        graphic={data.g3.childImageSharp.gatsbyImageData}
        gHeight={471}
        gWidth={627}
      />

      <SplitScreen
        textMax="500px"
        head="Engage & Recruit"
        sub="100k Mentors allows recruiters and hiring
        managers to build thousands of connections over the course of a year. The program enables mentors to identify and engage the next generation of talent. With on-campus recruiting heavily curtailed or canceled, our program creates a personal and focused approach to recruiting at scale."
        graphic={data.g4.childImageSharp.gatsbyImageData}
        gHeight={765}
        gWidth={565}
      />

      <SplitScreen
        reverse
        innerMax="1150px"
        textMax="550px"
        head="Connect, Retain & Grow"
        sub="Employees are more likely to remain at their company if they are in a mentoring program. Through the interactions with their mentees, employees will gain diverse perspectives, which can be incorporated into future products and business ideas."
        graphic={data.g5.childImageSharp.gatsbyImageData}
        gHeight={704}
        gWidth={507}
        pad={'8rem 23px 12rem'}
      />

      <HeaderBlock
        headMax={1000}
        headerText="Why should your company get involved and take the pledge?"
        pad="0 1.8rem 3rem"
      />

      <SlideTrifold cards={companiesPageData.trifold1.cards} />

      <HeaderBlock
        pad="12rem 0 3rem"
        headerText={`Any of your employees can become a mentor.
        Meet some of ours.`}
      />

      <Showcase
        content={companiesPageData.showcase1.cards}
        headPhrase="Looking to help with:"
      />

      <Founders />

      <HeaderBlock
        pad="8rem 2rem 8rem"
        headerText="Discover a new way to recruit the next generation of workers"
        subText={
          <p>
            Connect to potential recruits in a novel way while giving your
            employees an opportunity to have a meaningful impact in their
            communities
          </p>
        }
        headMax={1050}
        subMax={800}
      >
        <div style={{ display: `flex`, paddingTop: '2rem' }}>
          <CtaButton outLink="../advocate-materials.pdf">
            Advocate Materials
          </CtaButton>
        </div>
      </HeaderBlock>
    </Layout>
  );
}

export default CompaniesPage
