import React from 'react'
import { graphql } from 'gatsby'

// Shared UI
import {
  OverlapBlock,
  CtaButton,
  SplitScreen,
  Showcase,
  HeaderBlock,
} from 'shared-ui'

// Features
import Layout from '../../features/Layout'
import SEO from '../../features/Seo'

export const query = graphql`
  query FeaturesPageQuery {
    hero: file(relativePath: { eq: "featuresHero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 586, quality: 100, layout: CONSTRAINED)
      }
    }
    g1: file(relativePath: { eq: "featuresG1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 721, quality: 100, layout: CONSTRAINED)
      }
    }
    g2: file(relativePath: { eq: "featuresG2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 757, quality: 100, layout: CONSTRAINED)
      }
    }
    g3: file(relativePath: { eq: "featuresG3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 590, quality: 100, layout: CONSTRAINED)
      }
    }
    g4: file(relativePath: { eq: "featuresG4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 493, quality: 100, layout: CONSTRAINED)
      }
    }
    showcase1: file(relativePath: { eq: "showcase1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
      }
    }
    showcase2: file(relativePath: { eq: "showcase2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
      }
    }
    showcase3: file(relativePath: { eq: "showcase3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

const FeaturesPage = ({ data }) => {
  const featuresPageData = {
    hero: {
      pad: '3rem 2rem 0',
      bgColor: '#f9faff',
      innerMax: '1260px',
      max: '586px',
      headMax: '450px',
      heroText: 'Built for students, \n by students',
      mobileBar: {
        text: 'Learn More About The Program  |  Click Here',
        to: '/mentors/downloads/',
      },
      subText: (
        <p>
          The 100k Mentor app is designed for the way that people communicate
          today. It is built to be available whenever the user is and it is
          highly secure and encrypted.
        </p>
      ),
      graphic: data.hero.childImageSharp.gatsbyImageData,
      vectors: [
        {
          vector: null,
          styles: {
            bottom: '-26px',
            right: '0',
            position: 'absolute',
            background: `linear-gradient(96deg, rgba(248,101,122,1) 0%, rgba(75,52,237,1) 100%)`,
            width: '100%',
            height: '90px',
            transform: `rotate(-1.6deg)`,
          },
        },
      ],
    },
    showcase1: {
      cards: [
        {
          image: data.showcase1.childImageSharp.gatsbyImageData,
          name: `Kelsey Cavanaugh`,
          role: `Healthcare Manager at Protiviti`,
          headPhrase: 'Looking to help with:',
          options: [
            `Career Planning`,
            `Effective Networking`,
            `Interview Strategies`,
          ],
        },
        {
          image: data.showcase2.childImageSharp.gatsbyImageData,
          name: `Tracy Preston`,
          role: `Executive VP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [`Building Personal Brand`, `Career Advice`, `Networking`],
        },
        {
          image: data.showcase3.childImageSharp.gatsbyImageData,
          name: `Stefanie Tsen`,
          role: `SVP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [
            `Understanding Marketing`,
            `Inspiring Others`,
            `Networking for Your Future`,
          ],
        },
      ],
    },
    footer: {
      head: `Register today!`,
      sub: `Get started helping the next generation identify their career path`,
      buttons: (
        <CtaButton slug="/mentors/downloads/" inverse bgColor="white">
          Sign Up Today
        </CtaButton>
      ),
    },
  }

  return (
    <Layout pageData={featuresPageData}>
      <SEO title="Features" />

      <OverlapBlock
        head="A 21st century mentorship program"
        sub="The 100k Mentor app eliminates the constraints of traditional mentorship programs like in-person meetings and local connections, allowing companies to engage talent through a scalable, virtual and national model. Now a mentor’s impact and expertise can support students across the country and go wherever it is needed most. Anytime. Anywhere."
        buttons={
          <CtaButton outLink="/advocate-materials.pdf">
            Advocate Materials
          </CtaButton>
        }
        imgMargin="54px 0px 54px -90px"
        graphic={data.g1.childImageSharp.gatsbyImageData}
        maxWidth={721}
        maxHeight={627}
      />

      <HeaderBlock
        pad="8rem 0"
        headerText={`We’ve rethought the entire mentorship experience \n to make it scalable, virtual, and automated`}
      />

      <SplitScreen
        first
        pad="0 2rem 8rem"
        head={'Easy to manage \n thanks to automated \n mentor matching'}
        sub="Our algorithms match employees with
        students based on shared characteristics
        such as career goals, personality and
        expertise to ensure a good fit. The entire
        process is automated and self-managed,
        making it a turn-key solution."
        graphic={data.g2.childImageSharp.gatsbyImageData}
        gWidth={757}
        gHeight={542}
        textMax="480px"
        innerMax="1100px"
      />

      <SplitScreen
        reverse
        head={'A guided mentorship program'}
        sub="A major reason mentorships fail is because they lack structure. To address this issue, the app includes guides covering in-demand topics along with tasks students can complete between sessions, laying a foundation for the relationship to thrive."
        graphic={data.g3.childImageSharp.gatsbyImageData}
        gWidth={590}
        gHeight={534}
        textMax="520px"
      />

      <SplitScreen
        pad="8rem 2rem 11rem"
        head={`Seasons keep the connections fresh and engaging`}
        sub="After every four month Season, mentors can take on new mentees, enabling your company to continue to build connections with potential recruits and keep employees engaged with additional students."
        graphic={data.g4.childImageSharp.gatsbyImageData}
        gWidth={493}
        gHeight={684}
      />
      <HeaderBlock headerText="Trusted by the best" pad="0 0 4rem 0" />
      <Showcase content={featuresPageData.showcase1.cards} />
    </Layout>
  )
}

export default FeaturesPage
