import React from 'react'

import { CtaButton } from 'shared-ui'

// Style Elements
import {
  PrincipalsHead,
  PrincipalsWrapper,
  PrincipalsBody,
  LogoWrapper,
} from './Principals.styles'

import { Logo } from '../../primitives/Icons'

const Principals = () => (
  <PrincipalsWrapper>
    <PrincipalsHead>
      <h1>100k</h1>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <h1>Program Principals</h1>
    </PrincipalsHead>
    <PrincipalsBody>
      <p className="head">Do More For Our Students</p>
      <p className="body">
        The 100k Mentor Challenge is a unique program that allows companies to
        build bridges to students who need guidance and come from
        underrepresented backgrounds. Through the program, your company becomes
        the solution, opening opportunities to incredibly talented people no
        matter where they come from. It also completely transforms and digitizes
        how a company builds relationships with their future workforce in this
        Coronavirus era. All this is done at scale, in a flexible and turnkey
        way.
      </p>
    </PrincipalsBody>
    <CtaButton outLink="../../advocate-materials.pdf">
      Advocate Materials
    </CtaButton>
  </PrincipalsWrapper>
)

export default Principals
