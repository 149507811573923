import React from 'react'

// Features
import Layout from '../features/Layout'
import SEO from '../features/Seo'

// Primitives
import { CtaButton, TermsOfService } from 'shared-ui'

const TermsPage = ({ data }) => {
  const termsPageData = {
    noSupporters: true,
    footer: {
      head: `Register today!`,
      sub: `Get started helping the next generation identify their career path`,
      buttons: (
        <CtaButton slug="/mentors/downloads/" inverse bgColor="white">
          Register
        </CtaButton>
      ),
    },
  }

  return (
    <Layout pageData={termsPageData}>
      <SEO title="Terms of Service" />
      <TermsOfService />
    </Layout>
  )
}

export default TermsPage
