import React from 'react'

// Features
import Layout from '../features/Layout'
import SEO from '../features/Seo'

import { CtaButton, PrivacyPolicy } from 'shared-ui'

const PrivacyPage = () => {
  const privacyPageData = {
    noSupporters: true,
    footer: {
      head: `Register today!`,
      sub: `Get started helping the next generation identify their career path`,
      buttons: (
        <CtaButton slug="/mentors/downloads/" bgColor="white" inverse>
          Register
        </CtaButton>
      ),
    },
  }

  return (
    <Layout pageData={privacyPageData}>
      <SEO title="Privacy Policy" />
      <PrivacyPolicy />
    </Layout>
  )
}

export default PrivacyPage
