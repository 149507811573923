import React from 'react'
import { graphql } from 'gatsby'

// Shared UI
import { SplitScreen } from 'shared-ui'

// Features
import Layout from '../../features/Layout'
import SEO from '../../features/Seo'

// Primitives
import DownloadButtons from '../../primitives/DownloadButtons'

// Icons
import TopRight from '../../assets/downloads-top-right.svg'
import RightBack from '../../assets/downloads-right-back.svg'
import TopLeft from '../../assets/downloads-top-left.svg'

export const query = graphql`query StudentsDownloadsPageQuery {
  hero: file(relativePath: {eq: "downloadsHero.png"}) {
    childImageSharp {
      gatsbyImageData(width: 403, quality: 100, layout: CONSTRAINED)
    }
  }
  g1: file(relativePath: {eq: "downloadsG1.png"}) {
    childImageSharp {
      gatsbyImageData(width: 721, quality: 100, layout: CONSTRAINED)
    }
  }
  g2: file(relativePath: {eq: "downloadsG2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 556, quality: 100, layout: CONSTRAINED)
    }
  }
  g3: file(relativePath: {eq: "downloadsG3.png"}) {
    childImageSharp {
      gatsbyImageData(width: 652, quality: 100, layout: CONSTRAINED)
    }
  }
}
`

const DownloadsPage = ({ data }) => {
  const downloadsPageData = {
    hero: {
      noSlug: true,
      pad: '3rem 2rem 1px',
      bgColor: '#f9faff',
      innerMax: '1240px',
      max: '420px',
      headMax: '470px',
      mobileBar: {
        text: 'Have Questions?  |  Click Here',
        to: '/contact/',
      },
      heroText: "Mentorship built for today's generation",
      subText: (
        <p>
          Get the help and support you need to take you and your career to the
          next level.
        </p>
      ),
      graphic: data.hero.childImageSharp.gatsbyImageData,
      vectors: [
        {
          vector: <TopRight />,
          styles: { top: '0', right: '0', position: 'absolute' },
        },
        {
          vector: <TopLeft />,
          styles: { top: '0', left: '0', position: 'absolute' },
        },
        {
          vector: <RightBack />,
          styles: { bottom: '0', right: '0', position: 'absolute' },
        },
      ],
      children: <DownloadButtons heroButtons />,
    },
    noSupporters: true,
    footer: {
      buttons: <DownloadButtons />,
      head: `Sign up today!`,
      sub: `The 100k Mentor Challenge app is available on both Android and Apple operating systems.`,
      subMax: 700,
    },
  }
  return (
    <Layout pageData={downloadsPageData}>
      <SEO title="Download the app" />

      <SplitScreen
        pad="12rem 2rem 8rem"
        head="Build a network and support the next generation"
        sub="Extend your reach beyond a regional network to connect with people from across the country through the flexible, on-demand program. A mentorship can be anything the mentor/mentee wants it to be from an informal, monthly discussion to a focused weekly meeting to help the mentee achieve specific goals."
        graphic={data.g1.childImageSharp.gatsbyImageData}
        gWidth={613}
        gHeight={617}
        textMax="470px"
      />

      <SplitScreen
        reverse
        head="A simple, hastle free process"
        sub="Because the whole experience of finding a mentor or mentee is mobile and almost entirely automated, you’ll save time and won’t have to flip through endless profiles to find someone who matches your preferences."
        graphic={data.g2.childImageSharp.gatsbyImageData}
        gWidth={566}
        gHeight={689}
      />

      <SplitScreen
        head="See the impact"
        sub="Whether you are a mentee or mentor, within a few sessions you’ll see the impact of your work. As a mentee you will be on the path to achieving your professional dreams and aspirations. From a mentor’s perspective, you will be able to see the person you are supporting grow in innumerable ways right before your eyes, knowing that it was your guidance that helped them find their path."
        graphic={data.g3.childImageSharp.gatsbyImageData}
        gWidth={652}
        gHeight={752}
        pad="8rem 0 12rem"
      />
    </Layout>
  );
}

export default DownloadsPage
