import React from 'react'
import { graphql } from 'gatsby'
import { withArtDirection, getImage } from 'gatsby-plugin-image'
import { HowItWorks, CtaButton } from 'shared-ui'

import Layout from '../../features/Layout'
import Seo from '../../features/Seo'

import {
  Negotiations,
  Networking,
  Communication,
  Teamwork,
  MarketingStrategy,
  BusinessStrategy,
  Management,
  ResumeBuilding,
  Coding,
  Creativity,
  RemoteWork,
} from '../../primitives/Icons'

export const query = graphql`
  query MentorHIWPageQuery {
    split1Main: file(relativePath: { eq: "split1MentorsMain.png" }) {
      childImageSharp {
        gatsbyImageData(width: 448, quality: 100, layout: CONSTRAINED)
      }
    }
    split1Head: file(relativePath: { eq: "split1MentorsHead.png" }) {
      childImageSharp {
        gatsbyImageData(width: 88, quality: 100, layout: CONSTRAINED)
      }
    }
    split2p1: file(relativePath: { eq: "split2p1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 92, quality: 100, layout: FIXED)
      }
    }
    split2p2: file(relativePath: { eq: "split2p2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 92, quality: 100, layout: FIXED)
      }
    }
    split2p3: file(relativePath: { eq: "split2p3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 92, quality: 100, layout: FIXED)
      }
    }
    imageSectionLeft: file(
      relativePath: { eq: "imageSectionLeftMentors.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    imageSectionCenter: file(
      relativePath: { eq: "imageSectionCenterMentors.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 428, quality: 100, layout: CONSTRAINED)
      }
    }
    imageSectionRight: file(
      relativePath: { eq: "imageSectionRightMentors.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 332, quality: 100, layout: CONSTRAINED)
      }
    }
    lambs: file(relativePath: { eq: "statRowsLambs.png" }) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    tati: file(relativePath: { eq: "statRowsTatianna.png" }) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    split3a: file(relativePath: { eq: "split3a.png" }) {
      childImageSharp {
        gatsbyImageData(width: 374, quality: 100, layout: CONSTRAINED)
      }
    }
    arrowGMain: file(relativePath: { eq: "mentorArrowGMain.png" }) {
      childImageSharp {
        gatsbyImageData(width: 371, quality: 100, layout: CONSTRAINED)
      }
    }
    arrowRow: file(relativePath: { eq: "mentorArrowG.png" }) {
      childImageSharp {
        gatsbyImageData(width: 752, quality: 100, layout: CONSTRAINED)
      }
    }
    split4Main: file(relativePath: { eq: "fakeSlider.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
      }
    }
    antonS: file(relativePath: { eq: "antonS.png" }) {
      childImageSharp {
        gatsbyImageData(width: 60, quality: 100, layout: FIXED)
      }
    }
    last: file(relativePath: { eq: "HIWFooterA.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    last_tablet: file(relativePath: { eq: "HIWFooterA_tablet.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    last_mobile: file(relativePath: { eq: "HIWFooterA_mobile.png" }) {
      childImageSharp {
        gatsbyImageData(width: 356, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

const HowItWorksPage = ({ data }) => {
  const pageData = {
    noSupporters: true,
    howItWorks: {
      split1: {
        graphicHeadComponent: {
          img: data.split1Head.childImageSharp.gatsbyImageData,
          text: 'How Stefanie became a mentor for the 100k Mentor Challenge',
        },
        graphic: data.split1Main.childImageSharp.gatsbyImageData,
        quoteText: 'I’m interested in helping the next generation',
        quotePad: '4rem 0',
        arrowLink: '/mentors/',
        ctaLink: '/mentors/downloads/',
        maxWidth: '620px',
      },
      separator: {
        text1: "Here's how we",
        bold: 'find you',
        text2: 'the perfect mentee',
      },
      split2: {
        headerText: 'It all begins with a portfolio of potential candidates',
        subText:
          'A quick, interactive sign up enables us to understand your preferences and bring you a group of 2-6 top matches.',
        accordions: [
          {
            title: 'Where do the students come from?',
            content:
              'The students come from different underrepresented communities (Women, People of Color, LGBTQ, Veterans, 1st Generation College students, and students with disabilities) from across the country.',
          },
          {
            title: 'What is a student portfolio?',
            content:
              'Your custom portfolio is selected based on 25 different variables. Your portfolio represents the students who we feel you can help the most.',
          },
          {
            title: 'How much does it cost?',
            content:
              'The program is completely free for you. We wanted to have the greatest impact possible and we didn’t want cost to be a barrier.',
          },
        ],
        carouselContent: {
          content: [
            {
              title: 'Your Portfolio',
              profile: {
                fixed: data.split2p1.childImageSharp.gatsbyImageData,
                name: 'Justin Hoper',
                industry: 'Aerospace',
                uni: 'Northwestern University',
              },
              targetsSection: {
                heading: 'Targets',
                paras: [
                  <p>
                    Industry Desired: <span>Aerospace</span>
                  </p>,
                  <p>
                    Field: <span>Engineering</span>
                  </p>,
                ],
              },
              desiredSkills: {
                content: [
                  {
                    skill: 'Teamwork',
                    icon: <Teamwork />,
                  },
                  {
                    skill: 'Communication',
                    icon: <Communication />,
                  },
                  {
                    skill: 'Negotiations',
                    icon: <Negotiations />,
                  },
                  {
                    skill: 'Networking',
                    icon: <Networking />,
                  },
                  {
                    skill: 'Management',
                    icon: <Management />,
                  },
                  {
                    skill: 'Resume \n Building',
                    icon: <ResumeBuilding />,
                  },
                ],
              },
            },
            {
              title: 'Your Portfolio',
              profile: {
                fixed: data.split2p2.childImageSharp.gatsbyImageData,
                name: 'Arneil Farsh',
                industry: 'Business',
                uni: 'Howard University',
              },
              targetsSection: {
                heading: 'Targets',
                paras: [
                  <p>
                    Industry Desired: <span>SAS Technology</span>
                  </p>,
                  <p>
                    Field: <span>Product</span>
                  </p>,
                ],
              },
              desiredSkills: {
                content: [
                  {
                    skill: 'Negotiations',
                    icon: <Negotiations />,
                  },
                  {
                    skill: 'Networking',
                    icon: <Networking />,
                  },
                  {
                    skill: 'Teamwork',
                    icon: <Teamwork />,
                  },
                  {
                    skill: 'Communication',
                    icon: <Communication />,
                  },

                  {
                    skill: 'Marketing \n Strategy',
                    icon: <MarketingStrategy />,
                  },
                  {
                    skill: 'Business \n Strategy',
                    icon: <BusinessStrategy />,
                  },
                ],
              },
            },
            {
              title: 'Your Portfolio',
              profile: {
                fixed: data.split2p3.childImageSharp.gatsbyImageData,
                name: 'Tatianna Keller',
                industry: 'Engineering',
                uni: 'MIT',
              },
              targetsSection: {
                heading: 'Targets',
                paras: [
                  <p>
                    Industry Desired: <span>AI Technology</span>
                  </p>,
                  <p>
                    Field: <span>Engineering</span>
                  </p>,
                ],
              },
              desiredSkills: {
                content: [
                  {
                    skill: 'Coding',
                    icon: <Coding />,
                  },
                  {
                    skill: 'Creativity',
                    icon: <Creativity />,
                  },
                  {
                    skill: 'Negotiations',
                    icon: <Negotiations />,
                  },
                  {
                    skill: 'Remote Work',
                    icon: <RemoteWork />,
                  },
                  {
                    skill: 'Communication',
                    icon: <Communication />,
                  },
                  {
                    skill: 'Networking',
                    icon: <Networking />,
                  },
                ],
              },
            },
          ],
        },
      },
      imageSection: {
        headingWidth: '655px',
        heading:
          "Rank your portfolio and we optimize everyone's choices across the network",
        left: {
          fluid: data.imageSectionLeft.childImageSharp.gatsbyImageData,
          caption: 'John Tarell, USC',
        },
        center: {
          fluid: data.imageSectionCenter.childImageSharp.gatsbyImageData,
          caption: 'Arneil Farsh, Howard University',
        },
        right: {
          fluid: data.imageSectionRight.childImageSharp.gatsbyImageData,
          caption: 'Alicia Ramirez, UC Berkeley',
        },
      },
      statRowsImages: [
        {
          fluid: data.lambs.childImageSharp.gatsbyImageData,
          caption: 'Lambs Tao, NYU',
        },
        {
          fluid: data.tati.childImageSharp.gatsbyImageData,
          caption: 'Tatianna Keller, MIT',
        },
      ],
      statRows: [
        {
          stat: '100k',
          para: {
            head: 'Students',
            text:
              'Our national student pool comes from a diverse array of backgrounds, majors, and professional aspirations.',
          },
        },
        {
          stat: '4-6',
          para: {
            head: 'Potential Matches',
            text:
              'Instead of scrolling through endless profiles we bring you the top matches based on over 25 different variables.',
          },
        },
        {
          stat: '1',
          para: {
            head: 'Mentee',
            text:
              "Based on your rankings of your portfolio, we choose your best match. And now you're ready to start!",
          },
        },
      ],
      split3: {
        accordions: [
          {
            title: 'How do you choose my mentee?',
            content:
              'We combine your portfolio rankings with the portfolio ratings of the other mentors/mentees and then optimize who fits best with whom.',
          },
          {
            title: "What if I don't like my selection?",
            content:
              'We encourage you to try to work with your mentee, but sometimes the connection is not there so just contact us and we will get you set up with another student (limits apply).',
          },
          {
            title: 'What are the commitments?',
            content:
              'You are commiting to supporting a student over the course of a four month Season. We require that you come prepared to each session and meet with your mentee(s) at least once a month for an hour throughout the Season.',
          },
        ],
        graphic: {
          fluid: data.split3a.childImageSharp.gatsbyImageData,
          caption: 'Justin Hoper, Northwestern University',
        },
      },
      arrowGraphic: {
        head:
          'Guide your mentee over a four month Season, meeting once per month',
        leftP: {
          head: 'Give feeback and advice',
          para:
            'Teach the next generation how to successfully take their first steps and jump start their professional career.',
        },
        rightP: {
          head: 'Paths to guide your discussions',
          para:
            'We built a series of guides to support your discussions, laying the foundation for the relationship to thrive.',
          rowGraphic: data.arrowRow.childImageSharp.gatsbyImageData,
        },
        mainG: data.arrowGMain.childImageSharp.gatsbyImageData,
      },
      split4: {
        headerText: 'Just one hour a month can change a life',
        subHead: 'See & Feel Your Impact',
        graphic: data.split4Main.childImageSharp.gatsbyImageData,
        quoteText:
          'Working with my mentor completely changed my decision on what I wanted to do. Without their support, I could not have made the change, but looking back on it, I am so happy that I did.',
        profile: {
          img: data.antonS.childImageSharp.gatsbyImageData,
          name: 'Anton S.',
        },
        accordions: [
          {
            title: 'What happens at the end of a Season?',
            content:
              'At the end of a Season, you have the option of continuing with your match or requesting another match.',
          },
          {
            title: 'What if I am too busy next Season?',
            content:
              'We know things can get hectic which is why we have created an option for you to take a Season or two off whenever you need.',
          },
          {
            title: 'Can I stay connected with my mentee after the program?',
            content:
              'Absolutely. We hope you stay in touch and that it becomes a powerful relationship for both of you for years to come.',
          },
        ],
      },
      last: {
        headerText: 'Meet some incredible people along the way...',
        fluid: withArtDirection(getImage(data.last), [
          {
            media: `(max-width: 500px)`,
            image: getImage(data.last_mobile),
            height: data.last_mobile.childImageSharp.gatsbyImageData.height,
          },
          {
            media: `(max-width: 1200px)`,
            image: getImage(data.last_tablet),
            height: data.last_tablet.childImageSharp.gatsbyImageData.height,
          },
        ]),
      },
    },
    footer: {
      head: `Are you ready to connect with your mentee?`,
      headBgColor: '#441779',
      buttons: (
        <CtaButton
          slug="/mentors/downloads/"
          bgColor="white"
          textColor="#441779"
        >
          Get Started
        </CtaButton>
      ),
    },
  }
  return (
    <Layout pageData={pageData}>
      <Seo title="How it Works - Mentors" />
      <HowItWorks {...pageData.howItWorks} />
    </Layout>
  )
}

export default HowItWorksPage
