import React from 'react'
import PropTypes from 'prop-types'

import { Slide } from 'react-reveal'
/* eslint-disable-next-line */
import makeCarousel from 'react-reveal/makeCarousel'
import { useInView } from 'react-intersection-observer'
// Icons
import { Slide0, Slide1, Slide2 } from '../../primitives/Icons'
import InfoSlide from './features/InfoSlide'
import { SliderContainer, Dot, Dots } from './InfoSlider.styles'

const InfoSlider = ({ content }) => {
  const CarouselUI = ({ position, handleClick, children, total }) => (
    <SliderContainer>
      {children}

      <Dots>
        {Array(...Array(total)).map((val, i) => (
          <Dot key={`slider-${i}`} onClick={handleClick} data-position={i}>
            {i === position ? '●' : '○'}
          </Dot>
        ))}
      </Dots>
    </SliderContainer>
  )
  const Carousel = makeCarousel(CarouselUI)
  const { ref, inView } = useInView({
    rootMargin: '200px',
    triggerOnce: true,
  })
  return (
    <div ref={ref}>
      {inView && (
        <Carousel defaultWait={12000}>
          {content.map((slide, i) => (
            <Slide key={`info-slide-${i}`} right>
              {slide}
            </Slide>
          ))}
        </Carousel>
      )}
    </div>
  )
}

InfoSlider.defaultProps = {
  content: [
    <InfoSlide
      head="Step 1: Take the Pledge"
      sub="The 100k Mentor Challenge is designed to be flexible and adjust to each company’s unique needs and goals. We work with your team to determine the type of impact your company wants to have and design your company’s pledge around those focus areas."
      graphic={<Slide0 />}
    />,
    <InfoSlide
      head="Step 2: Roll Out & Sign Up"
      sub="Rolling the Challenge out is as simiple as sending out a few notices across your company and asking employees and a few corporate leaders to sign up for the app. After a quick registration process, your employees are ready for their first match!"
      graphic={<Slide1 />}
    />,
    <InfoSlide
      head="Step 3: Match & Connect"
      sub="Your employees are matched with their mentees and start their 4-month mentor program with the support of our Guides to provide structure to the mentorship and ensure both sides achieve their goals."
      graphic={<Slide2 className="slide-3" />}
    />,
  ],
}

InfoSlider.propTypes = {
  content: PropTypes.array,
}

export default InfoSlider
