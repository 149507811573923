import styled from 'styled-components'

export const PrincipalsWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 7rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.slideBg};
  > * {
    max-width: 1100px;
  }
  padding-bottom: 6rem;
`

export const PrincipalsHead = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 6rem 2rem 3.8rem;
  > h1 {
    font-size: 44px;
    @media only screen and (max-width: 709px) {
      margin-top: 22px;
      font-size: 36px;
    }

    @media only screen and (max-width: 364px) {
      text-align: center;
    }
  }
  @media only screen and (max-width: 709px) {
    padding: 4rem 2rem 3.8rem;
  }
`

export const PrincipalsBody = styled.div`
  .head {
    margin-bottom: 1.2rem;
    font-size: 34px;
    font-weight: 500;

    text-align: center;
    @media only screen and (max-width: 709px) {
      font-size: 26px;
    }
  }

  .body {
    font-size: 25px;
    line-height: 2.3rem;
    font-weight: 400;
    @media only screen and (max-width: 709px) {
      font-size: 21px;
    }
  }
  padding: 0 2rem 4rem;
`

export const LogoWrapper = styled.div`
  margin: 0 19px;
  > svg {
    fill: ${({ theme }) => theme.colors.logo};
    width: 110px;
    height: 120px;
  }
`
