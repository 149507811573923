import React from 'react'
import { graphql } from 'gatsby'

// Shared UI
import { CtaButton, Showcase, HeaderBlock, ListItem } from 'shared-ui'

// Features
import Layout from '../../features/Layout'
import SEO from '../../features/Seo'

// Primitives
import Signatories from '../../primitives/Signatories'
import Principals from '../../features/Principals'
import DownloadButtons from '../../primitives/DownloadButtons'

export const query = graphql`query MentorPledgePageQuery {
  showcase1: file(relativePath: {eq: "showcase1.png"}) {
    childImageSharp {
      gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
    }
  }
  showcase2: file(relativePath: {eq: "showcase2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
    }
  }
  showcase3: file(relativePath: {eq: "showcase3.png"}) {
    childImageSharp {
      gatsbyImageData(width: 358, quality: 100, layout: CONSTRAINED)
    }
  }
}
`

const MentorPledgePage = ({ data }) => {
  const mentorPledgePageData = {
    noSupporters: true,
    footer: {
      buttons: <DownloadButtons />,
      head: `Register today!`,
      sub: `Register for the 100k Mentor Challenge and you will be prioritized to get a match`,
    },
    showcase1: {
      cards: [
        {
          image: data.showcase1.childImageSharp.gatsbyImageData,
          name: `Kelsey Cavanaugh`,
          role: `Healthcare Manager at Protiviti`,
          headPhrase: 'Looking to help with:',
          options: [
            `Career Planning`,
            `Effective Networking`,
            `Interview Strategies`,
          ],
        },
        {
          image: data.showcase2.childImageSharp.gatsbyImageData,
          name: `Tracy Preston`,
          role: `Executive VP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [`Building Personal Brand`, `Career Advice`, `Networking`],
        },
        {
          image: data.showcase3.childImageSharp.gatsbyImageData,
          name: `Stefanie Tsen`,
          role: `SVP at Neiman Marcus Group`,
          headPhrase: 'Looking to help with:',
          options: [
            `Understanding Marketing`,
            `Inspiring Others`,
            `Networking for your Future`,
          ],
        },
      ],
    },
    listItems: [
      {
        color: `#fe4f67`,
        word: `Acknowledge`,
        paragraph: `college students are at risk of being left behind, especially those from underrepresented backgrounds who will continue to find themselves seriously disadvantaged compared to their peers.`,
      },
      {
        color: `#d04365`,
        word: `Embrace`,
        paragraph: `being the solution, as professionals from all industries and career stages can have an incredible impact on students.`,
      },
      {
        color: `#b13a63`,
        word: `Activate`,
        paragraph: `my network of colleagues, friends, and family to make a difference by joining the 100k Mentor Challenge.`,
      },
      {
        color: `#9d3462`,
        word: `Commit`,
        paragraph: `one hour per month to a mentee for at least four months.`,
      },
      {
        color: `#842d60`,
        word: `Reflect`,
        paragraph: `on and sharing your professional journey with your mentee including the ups and downs and how you overcame obstacles.`,
      },
      {
        color: `#57205d`,
        word: `Rebuild`,
        paragraph: `the devastated student networks and support systems in new ways that are more resilient to sudden shocks.`,
      },
      {
        color: `#38175b`,
        word: `Achieve`,
        paragraph: `success by reducing feelings of isolation and uncertainty while building a positive relationship with your mentee and helping them move forward in their professional journey.`,
      },
    ],
  }
  return (
    <Layout pageData={mentorPledgePageData}>
      <SEO title="Mentor Pledge" />

      <HeaderBlock
        headerText="The 100k Mentor Program Advocate Pledge"
        pad="7rem 2rem 0"
      />
      <HeaderBlock
        pad="0"
        subMax={1050}
        subText={
          <>
            <p style={{ textAlign: `center` }}>
              I commit to the 100k Mentor Challenge to support and mentor
              college students during this difficult time. I will offer my
              experiences and guidance to my mentee to help them continue to
              develop as a young professional.
            </p>
            <p
              style={{
                textAlign: `left`,
                fontWeight: `500`,
                color: `black`,
                fontSize: `2rem`,
                padding: `20px 0 0 0`,
                marginBottom: `.6rem`,
              }}
            >
              To reach our goal, I will:
            </p>
          </>
        }
      >
        <ol>
          {mentorPledgePageData.listItems.map((item, i) => (
            <ListItem
              key={`mentor-pledge-item-${i}`}
              liColor={item.color}
              paragraph={item.paragraph}
              word={item.word}
            />
          ))}
        </ol>
      </HeaderBlock>
      <div style={{ width: `100%`, display: `flex`, justifyContent: `center` }}>
        <CtaButton slug={'/mentors/downloads/'}>Take the pledge</CtaButton>
      </div>
      <Principals />
      <HeaderBlock headerText="Our Signatories" pad="8rem 24px 2rem" />
      <Signatories />
      <HeaderBlock
        headerText="With just one hour a month, a mentor can change a student’s life."
        pad="4rem 0 3rem 0"
        headMax={1080}
      />

      <Showcase
        content={mentorPledgePageData.showcase1.cards}
        headPhrase="Looking to help with:"
      />
    </Layout>
  )
}

export default MentorPledgePage
