import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Controller, Scene } from 'react-scrollmagic'
import { GlobeLg, SchoolExpand, Handshake } from '../Icons'
import { HeaderHat } from '../HeaderBlock/HeaderBlock.styles'
import {
  ParallaxWrapper,
  ParallaxOuter,
  Cross,
  Crosses,
} from './CrossOutIcons.styles'

const contentArr = [
  {
    icon: <Handshake style={{ marginTop: '30px' }} />,
    text: 'In-Person Internships',
  },
  { icon: <GlobeLg />, text: 'Diversity Initiatives on Campus' },
  { icon: <SchoolExpand />, text: 'Expanding Target Schools' },
]

const CrossOutIcons = () => (
  <ParallaxOuter>
    <Controller>
      <Scene duration={800} triggerElement="#trigger">
        {(progress) => (
          <ParallaxWrapper>
            <div
              className={`${progress > 0.6 ? 'fadeOutUp' : 'fadeInDown'}`}
              style={{
                color: `white`,
                display: `flex`,
                flexDirection: `column`,
                alignItems: `center`,
              }}
            >
              <HeaderHat />
              <h2
                style={{
                  color: `white`,
                }}
              >
                Companies had just started to figure out how to build talent
                pipelines and create opportunities:
              </h2>
            </div>
            <Crosses className={`${progress > 0.6 ? 'moveUp' : 'moveDown'}`}>
              {contentArr.map((item) => (
                <Cross key={uuidv4()}>
                  <div
                    className={`line ${progress > 0.6 ? 'visible' : 'hidden'}`}
                  />
                  <div
                    className={`line right ${
                      progress > 0.6 ? 'visible' : 'hidden'
                    }`}
                  />
                  {item.icon}
                  <p>{item.text}</p>
                </Cross>
              ))}
            </Crosses>
            <p
              className={`${
                progress < 0.6 ? 'hidden moveDown2' : 'visible moveUp2'
              }`}
              style={{
                position: `absolute`,
                boxSizing: `border-box`,
                fontSize: `21px`,
                color: `white`,
              }}
            >
              The Coronavirus pandemic has eliminated almost all of these
              programs, which are crucial to helping students from
              underrepresented backgrounds build the networks and gain the
              exposure that many do not have on their own and lead to full time
              jobs.
            </p>
          </ParallaxWrapper>
        )}
      </Scene>
    </Controller>
  </ParallaxOuter>
)

export default CrossOutIcons
